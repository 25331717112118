import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import User from './User';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UserDialog = (props) => {
    const classes = useStyles();

    const { open, toggle, customer } = props

    const handleClose = () => {
        toggle()
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} style={{zIndex : 1032}}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Kullanıcı Detayı
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        Kapat
                    </Button>
                </Toolbar>
            </AppBar>
            <User customer={customer} noHeader={true} />
        </Dialog>
    );
}

export default UserDialog