import React, { useEffect, useState } from 'react'
import { updateCustomer } from '_services/user'
import Swal from 'sweetalert2'
import { getFitnessPackagesV2 } from '_services/subscription'

import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import Button from 'components/CustomButtons/Button'
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment'
import 'moment/locale/tr'

const useStyles = makeStyles(styles)

const Subscription = ({ token, customer, setCustomer }) => {

    const [packageValidUntil, setPackageValidUntil] = useState("")
    const [packages, setpackages] = useState([])
    const [subpackages, setsubpackages] = useState([])
    const [_package, _setPackage] = useState("")
    const [ packageSubname, setpackageSubname ] = useState("")

    const classes = useStyles()

    useEffect(() => {
        setPackageValidUntil(customer.packageValidUntil)
        _setPackage(customer._package ? customer._package._id : "")
        setpackageSubname(customer.packageSubname)
    }, [customer])

    useEffect(() => {
        if(_package !== ""){
            const index = packages.map(p => p._id).indexOf(_package)
            if(index > -1){
                setsubpackages(packages[index].prices)
            }
        }
    },[_package])


    const handleSubmit = () => {
        const data = { id: customer._id, packageValidUntil, _package, packageSubname }
        updateCustomer(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setCustomer({ ...customer, packageValidUntil })
                    Swal.fire({
                        icon: "success",
                        title: "Kullanıcı başarıyla güncellendi."
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Hata : " + err
                })
            })
    }

    useEffect(() => {
        getFitnessPackagesV2()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setpackages(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <GridContainer>
            <GridItem xs={10}>
                <GridContainer>
                    <GridItem xs={4}>
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{ marginBottom: 10 }}
                        >
                            <InputLabel
                                htmlFor="multiple-select"
                                className={classes.selectLabel}
                            >
                                Paket
                            </InputLabel>
                            <Select
                                value={_package}
                                fullWidth
                                onChange={e => _setPackage(e.target.value)}
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                inputProps={{
                                    name: "multipleSelect",
                                    id: "multiple-select"
                                }}
                            >
                                <MenuItem
                                    value={""}
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    Paket Mevcut Değil
                                </MenuItem>
                                {packages.map(p => {
                                    return (
                                        <MenuItem
                                            key={p._id}
                                            value={p._id}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            {p.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={4}>
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                            style={{ marginBottom: 10 }}
                        >
                            <InputLabel
                                htmlFor="multiple-select"
                                className={classes.selectLabel}
                            >
                                Paket
                            </InputLabel>
                            <Select
                                value={packageSubname}
                                fullWidth
                                onChange={e => {
                                    const index = subpackages.map( s => s.name).indexOf(e.target.value)
                                    if(index > -1){
                                        setPackageValidUntil(moment().add(subpackages[index].days,"days"))
                                    }
                                    setpackageSubname(e.target.value)
                                }}
                                MenuProps={{ className: classes.selectMenu }}
                                classes={{ select: classes.select }}
                                inputProps={{
                                    name: "multipleSelect",
                                    id: "multiple-select"
                                }}
                            >
                                <MenuItem
                                    value={""}
                                    classes={{
                                        root: classes.selectMenuItem
                                    }}
                                >
                                    Paket Mevcut Değil
                                </MenuItem>
                                {subpackages.map(p => {
                                    return (
                                        <MenuItem
                                            key={p._id}
                                            value={p.name}
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            {p.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={4}>
                        <DatePicker
                            label="Paket Bitiş Zamanı"
                            value={packageValidUntil || moment().format('YYYY-MM-DD')}
                            onChange={date => setPackageValidUntil(moment(date).format('YYYY-MM-DD'))}
                            format='DD MMMM YYYY'
                            okLabel='TAMAM'
                            cancelLabel='KAPAT'
                            fullWidth
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={2}>
                <Button color='primary' variant='contained' onClick={handleSubmit} >Güncelle</Button>
            </GridItem>
        </GridContainer>
    )
}

export default Subscription