import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import SortableGrid from 'components/Dnd/SortableGrid'
import { arrayMoveImmutable } from 'array-move';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import { getFitnessPackagesV2, updateFitnessPackage } from '_services/subscription'

const useStyles = makeStyles(styles)

const Subscriptions = props => {
    const [user] = useAtom(userAtom)
    const [items, setItems] = useState([])
    const { token } = user


    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) {
            return
        }
        let newArr = arrayMoveImmutable(items, oldIndex, newIndex)
        newArr = newArr.map((item, index) => {
            const data = { id: item._id, order: index }
            updateFitnessPackage(token, data)
            return { ...item, order: index }
        })
        setItems(newArr)
    }
    const classes = useStyles()

    useEffect(() => {
        getFitnessPackagesV2()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setItems(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])



    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Paketler</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/paket-detay'>
                                    <Button color='success' style={{ float: "right" }}> Paket Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                <SortableGrid
                    items={items}
                    axis='xy'
                    onSortEnd={onSortEnd}
                    distance={1}
                />
            </GridItem>
        </GridContainer>
    )
}

export default Subscriptions