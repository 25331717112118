import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button'
import { useAtom } from "jotai"
import { userAtom } from "_atoms/user"
import Swal from 'sweetalert2'
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { createWorkout, updateWorkout } from "_services/workout";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Divider, Tooltip } from "@material-ui/core"
import { Fragment } from "react"
import AddMovementDialog from './AddMovementDialog'
import Vimeo from '@u-wave/react-vimeo';
import WorkoutTypeAndLevel from "./WorkoutTypeAndLevel"
import ReactPlayer from "react-player"

const useStyles = makeStyles({ ...styles, ...styles2 })

const Movement = props => {

    const [user] = useAtom(userAtom)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [tips, setTips] = useState([])
    const [forMale, setForMale] = useState(true)
    const [forFemale, setForFemale] = useState(true)
    const [workoutType, setWorkoutType] = useState("")
    const [workoutLevel, setWorkoutLevel] = useState("")
    const [workoutSection, setWorkoutSection] = useState("")
    const [restHours, setRestHours] = useState(22)
    const [movementObjects, setMovementObjects] = useState([])
    const [movementInfo, setMovementInfo] = useState([])

    const [openMovementDialog, setOpenMovementDialog] = useState(false)
    const [tip, setTip] = useState("")

    const handleMovementDialogToggle = () => {
        setOpenMovementDialog(!openMovementDialog)
    }

    const [mode, setMode] = useState(0)
    const [id, setId] = useState('')

    const { location } = props

    useEffect(() => {
        if (location && location.workout && location.workout._id) {
            const { workout } = location
            console.log(workout)
            setMode(1)
            setId(workout._id || '')
            setName(workout.name || '')
            setDescription(workout.description || '')
            setForMale(workout.forMale)
            setForFemale(workout.forFemale)
            setWorkoutLevel(workout.workoutLevel || '')
            setWorkoutType(workout.workoutType || '')
            setWorkoutSection(workout.workoutSection || "")
            setMovementObjects(workout.movements || [])
            setTips(workout.tips || [])
            setMovementInfo(workout.movementInfo || [])
            setRestHours(workout.restHours || 0)
        }
        return (() => {
            setMode(0)
            setId('')
            setName('')
            setDescription('')
            setForMale(true)
            setForFemale(true)
            setWorkoutLevel('')
            setWorkoutType('')
            setMovementObjects([])
            setTips([])
        })
    }, [location])

    const { token } = user

    const classes = useStyles()

    const handleAddWorkout = () => {
        const data = { name, description, forFemale, forMale, workoutSection, tips, workoutType, workoutLevel, restHours, movements: movementObjects.map(m => m._id), movementInfo }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman adını giriniz."
            })
            return
        }
        if (workoutType === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman tipini seçiniz."
            })
            return
        }
        if (workoutLevel === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman seviyesini seçiniz."
            })
            return
        }
        if (movementObjects.length === 0) {
            Swal.fire({
                icon: "warning",
                title: "En az bir hareket ekleyiniz."
            })
            return
        }
        createWorkout(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Antrenman oluşturuldu."
                    })
                    props.history.goBack()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleUpdateWorkout = () => {
        const data = { id, name, description, forFemale, forMale, workoutSection, tips, workoutType, workoutLevel, restHours, movements: movementObjects.map(m => m._id), movementInfo: movementInfo.slice(0, movementObjects.length) }
        if (name === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman adını giriniz."
            })
            return
        }
        if (workoutType === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman tipini seçiniz."
            })
            return
        }
        if (workoutLevel === "") {
            Swal.fire({
                icon: "warning",
                title: "Antrenman seviyesini seçiniz."
            })
            return
        }
        if (movementObjects.length === 0) {
            Swal.fire({
                icon: "warning",
                title: "En az bir hareket ekleyiniz."
            })
            return
        }
        updateWorkout(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Antrenman Düzenlendi."
                    })
                    props.history.goBack()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleAddTip = () => {
        if (tip.length > 5) {
            setTips([
                ...tips,
                tip
            ])
            setTip("")
        } else {
            Swal.fire({
                icon: "warning",
                title: "Tavsiye çok kısa!"
            })
        }
    }

    const handleRemoveMovement = index => {
        setMovementObjects([
            ...movementObjects.slice(0, index),
            ...movementObjects.slice(index + 1)
        ])
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={10}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}> Antrenman {mode === 0 ? 'Ekle' : "Düzenle"}</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={2}>
                                {mode === 0
                                    ? <Button color='success' block onClick={handleAddWorkout}>
                                        Antrenman Ekle
                                    </Button>
                                    : <Button color='success' block onClick={handleUpdateWorkout}>
                                        Antrenman Düzenle
                                    </Button>
                                }
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Antrenman Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: (e) => setName(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <GridContainer alignItems='flex-end'>
                                    <GridItem xs={6}>
                                        <div className={classes.checkboxAndRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => setForMale(!forMale)}
                                                        checked={forMale}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Erkeklere Uygun"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <div className={classes.checkboxAndRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => setForFemale(!forFemale)}
                                                        checked={forFemale}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Kadınlara Uygun"
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer alignItems='flex-end'>
                                    <WorkoutTypeAndLevel
                                        workoutType={workoutType}
                                        setWorkoutType={setWorkoutType}
                                        workoutLevel={workoutLevel}
                                        setWorkoutLevel={setWorkoutLevel}
                                        workoutSection={workoutSection}
                                        setWorkoutSection={setWorkoutSection}
                                    />
                                    <GridItem xs={12} md={3} style={{ marginBottom: -17 }}>
                                        <CustomInput
                                            labelText='Dinlenme Süresi (Saat)'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: restHours,
                                                onChange: (e) => setRestHours(e.target.value),
                                                type: 'number',
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} style={{ marginBottom: 50, marginTop: 50 }}>
                                <GridContainer>
                                    <GridItem xs={12} md={10}>
                                        <h4>{movementObjects.length === 0 ? "Hareketler" : (movementObjects.length + ' Hareket Eklendi')}</h4>
                                    </GridItem>
                                    <GridItem xs={12} md={2}>
                                        <Button color='rose' onClick={handleMovementDialogToggle} block>Hareketleri Gör</Button>
                                    </GridItem>
                                    {movementObjects.length === 0 &&
                                        <GridItem xs={12}>
                                            <p style={{ fontSize: 16, fontWeight: 400 }}>Henüz hareket eklemediniz.</p>
                                        </GridItem>
                                    }
                                    {movementObjects.length > 0 &&
                                        <GridItem xs={12} style={{ marginTop: 30 }}>
                                            <h4>Eklenen Hareketler</h4>
                                            {movementObjects.length === 0 &&
                                                <p style={{ fontSize: 16, fontWeight: 300 }}>Hareket eklemediniz.</p>
                                            }
                                            <GridContainer justify='center'>
                                                {movementObjects.map((mov, i) => {
                                                    return (
                                                        <GridItem xs={12} md={8} key={mov._id} style={{ border: "1px solid lightgray", borderRadius: 10 }}>
                                                            <GridContainer>
                                                                <GridItem xs={2}>
                                                                    {mov.videoUrl
                                                                        ? <ReactPlayer
                                                                            url={mov.videoUrl}
                                                                            width={150}
                                                                            height={150}
                                                                            controls
                                                                        />
                                                                        : <Vimeo
                                                                            video={mov.videoId}
                                                                            width={150}
                                                                            height={150}
                                                                            autopause
                                                                        />
                                                                    }

                                                                </GridItem>
                                                                <GridItem xs={8} style={{ height: "100%", margin: "auto" }}>
                                                                    <h5>{mov.name}</h5>
                                                                    {mov.description}
                                                                    {movementInfo.length === movementObjects.length &&
                                                                        <GridContainer>
                                                                            <GridItem xs={4}>
                                                                                <CustomInput
                                                                                    labelText='Ağırlık - Seviye'
                                                                                    formControlProps={{
                                                                                        fullWidth: true,
                                                                                    }}
                                                                                    inputProps={{
                                                                                        value: movementInfo[i].weight,
                                                                                        onChange: (e) => {
                                                                                            setMovementInfo([
                                                                                                ...movementInfo.slice(0, i),
                                                                                                { ...movementInfo[i], weight: e.target.value },
                                                                                                ...movementInfo.slice(i + 1)
                                                                                            ])
                                                                                        },
                                                                                        type: 'text',
                                                                                    }}
                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={4}>
                                                                                <CustomInput
                                                                                    labelText='Tekrar Sayısı - Süre'
                                                                                    formControlProps={{
                                                                                        fullWidth: true,
                                                                                    }}
                                                                                    inputProps={{
                                                                                        value: movementInfo[i].repeat,
                                                                                        onChange: (e) => {
                                                                                            setMovementInfo([
                                                                                                ...movementInfo.slice(0, i),
                                                                                                { ...movementInfo[i], repeat: e.target.value },
                                                                                                ...movementInfo.slice(i + 1)
                                                                                            ])
                                                                                        },
                                                                                        type: 'text',
                                                                                    }}
                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={4}>
                                                                                <CustomInput
                                                                                    labelText='Set Sayısı'
                                                                                    formControlProps={{
                                                                                        fullWidth: true,
                                                                                    }}
                                                                                    inputProps={{
                                                                                        value: movementInfo[i].sets,
                                                                                        onChange: (e) => {
                                                                                            setMovementInfo([
                                                                                                ...movementInfo.slice(0, i),
                                                                                                { ...movementInfo[i], sets: e.target.value },
                                                                                                ...movementInfo.slice(i + 1)
                                                                                            ])
                                                                                        },
                                                                                        type: 'text',
                                                                                    }}
                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    }
                                                                </GridItem>
                                                                <GridItem xs={2} style={{ height: "100%", margin: "auto" }}>
                                                                    <Tooltip title='Çıkar'>
                                                                        <IconButton onClick={() => handleRemoveMovement(i)}>
                                                                            <HighlightOffIcon color='primary' size={30} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </GridItem>
                                                            </GridContainer>
                                                            {/* { (i + 1) !== movementObjects.length &&
                                                                <Divider style={{ backgroundColor: "lightgray", marginTop: 20, marginBottom: 20 }} />
                                                            } */}
                                                        </GridItem>
                                                    )
                                                })}
                                            </GridContainer>
                                        </GridItem>
                                    }
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} style={{ marginBottom: 50 }}>
                                <h4>Tavsiyeler</h4>
                                <GridContainer alignItems='flex-end'>
                                    <GridItem xs={11}>
                                        <CustomInput
                                            labelText='Antrenman Tavsiyesi'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: tip,
                                                onChange: (e) => setTip(e.target.value),
                                                type: 'text',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={1}>
                                        <Tooltip title='Tavsiye Ekle'>
                                            <IconButton onClick={handleAddTip}>
                                                <AddCircleOutlineIcon color='primary' size={30} />
                                            </IconButton>
                                        </Tooltip>
                                    </GridItem>
                                    {tips.map((t, i) => {
                                        return (
                                            <Fragment key={t + i}>
                                                <GridItem xs={11}>
                                                    <p style={{ fontWeight: "500", fontSize: 16 }}>{t}</p>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip title='Tavsiyeyi Sil'>
                                                        <IconButton onClick={() => {
                                                            setTips([
                                                                ...tips.slice(0, i),
                                                                ...tips.slice(i + 1)
                                                            ])
                                                        }}>
                                                            <HighlightOffIcon color='primary' size={30} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </GridItem>
                                                <Divider />
                                            </Fragment>
                                        )
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText='Antrenman Açıklaması'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: description,
                                        multiline: true,
                                        rows: 4,
                                        onChange: (e) => setDescription(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={10}>

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <AddMovementDialog
                open={openMovementDialog}
                toggle={handleMovementDialogToggle}
                movementObjects={movementObjects}
                setMovementObjects={setMovementObjects}
                movementInfo={movementInfo}
                setMovementInfo={setMovementInfo}
            />
        </GridContainer>
    )
}

export default Movement