import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useAtom } from 'jotai'
import Button from 'components/CustomButtons/Button';
import React, { useEffect, useState } from 'react'
import { userAtom } from '_atoms/user'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/tr'
import PaginatedGrid from 'components/DataGrid/PaginatedGrid'
import { getCustomersWithPackages } from '_services/customer'
import UserDialog from './UserDialog'
import { FormControl, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core'
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { getFitnessPackagesV2 } from '_services/subscription'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'


const useStyles = makeStyles(styles)

const image = require('../../assets/img/placeholder.jpeg')

const Users = props => {
    const [user] = useAtom(userAtom)
    const [customers, setCustomers] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0)
    const [packages, setPackages] = useState([])
    const [_package, _setPackage] = useState("0")

    const [first, setfirst] = useState("")
    const [family, setfamily] = useState("")

    const router = useRouteMatch()

    const [showFilter, setShowFilter] = useState(0)
    const [sortFilter, setSortFilter] = useState(router.path.split("/").pop() === "tum-kullanicilar" ? 8 : 6)

    const { token } = user

    const classes = useStyles()

    const [userDialog, setUserDialog] = useState(false)
    const [customer, setCustomer] = useState({})

    const userDialogToggle = () => {
        setUserDialog(!userDialog)
    }


    useEffect(() => {
        getFitnessPackagesV2()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setPackages(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        getData()
    }, [pageSize, currentPage, showFilter, sortFilter, _package])

    const getData = () => {
        const data = { page: currentPage, pageLength: pageSize, showFilter, sortFilter, _package: _package === "0" ? null : _package, first, family, active: router.path.split("/").pop() !== "tum-kullanicilar" }
        console.log(data)
        getCustomersWithPackages(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setCustomers(res.data.data)
                    setTotal(res.data.total)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const columns = [
        { name: 'avatar', title: "Avatar" },
        { name: 'lastLogin', title: "Son Giriş/Kayıt" },
        { name: 'name', title: "Müşteri Adı" },
        { name: 'package', title: "Paketi" },
        { name: 'packageEnds', title: "Paket Bitiş" },
        { name: 'programEnds', title: "Program Bitiş" },
        { name: 'action', title: "Detay" }
    ];

    const rows = () => {
        return customers.map((p) => ({
            avatar: <img src={p.avatar ? p.avatar : image} alt="user" style={{ height: 80, width: 80, margin: 0, objectFit: "cover", borderRadius: 8 }} />,
            name: p.name ? (p.name.first + ' ' + p.name.family) : "",
            package: p._package ? p._package.name : ' - ',
            packageEnds: p.packageValidUntil ? moment(p.packageValidUntil).format("DD MMMM YYYY") : " - ",
            lastLogin: <div>
                <p>
                    {(p.lastLogIn) ? moment(p.lastLogIn).format("DD MMMM YYYY HH:mm") : " - "}
                </p>
                <p>
                    {(p.created) ? moment(p.created).format("DD MMMM YYYY HH:mm") : " - "}
                </p>
            </div>,
            programEnds: (p.currentProgram && p.currentProgram._id) ? moment(p.currentProgram.endDate).format("DD MMMM YYYY") : " - ",
            action: <Button color='primary' onClick={() => {
                setCustomer(p)
                userDialogToggle()
            }} >Detay</Button>
        }));
};

const defaultColumnWidths = [
    { columnName: 'avatar', width: 150 },
    { columnName: 'name', width: 200 },
    { columnName: 'lastLogin', width: 200 },
    { columnName: 'packageEnds', width: 200 },
    { columnName: 'programEnds', width: 200 },
    { columnName: 'package', width: 200 },
    { columnName: 'action', width: 150 }
];

let options = [
    { value: 0, label: "Tümü" },
    { value: 1, label: "Programı Bitenler" },
    { value: 2, label: "Programı Verilmeyenler" },
]

let sortoptions = [
    { value: 0, label: "Normal" },
    { value: 1, label: "En Yeni Giriş Yapanlar" },
    { value: 2, label: "En Eski Giriş Yapanlar" },
    { value: 3, label: "Program Tarihi (Önce Eski)" },
    { value: 4, label: "Program Tarihi (Önce Yeni)" },
    { value: 5, label: "Paket Bitiş (Önce Yaklaşan)" },
    { value: 6, label: "Paket Bitiş (Önce Yaklaşmayan)" },
    { value: 7, label: "Kayıt Tarihi (Önce Eski)" },
    { value: 8, label: "Kayıt Tarihi (Önce Yeni)" },
]

return (
    <GridContainer>
        <GridItem xs={12}>
            <Card>
                <CardHeader>
                    <CardIcon color='rose'>
                        <h4 style={{ color: "#fff" }}>Kullanıcılar</h4>
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} md={8}>
                            <GridContainer>
                                <GridItem xs={6} md={4}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <InputLabel
                                            htmlFor="multiple-select"
                                            className={classes.selectLabel}
                                        >
                                            Göster
                                        </InputLabel>
                                        <Select
                                            value={showFilter}
                                            fullWidth
                                            onChange={e => setShowFilter(parseInt(e.target.value))}
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            inputProps={{
                                                name: "multipleSelect",
                                                id: "multiple-select"
                                            }}
                                        >
                                            {options.map(o => {
                                                return (
                                                    <MenuItem
                                                        value={o.value}
                                                        key={o.value}
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        {o.label}
                                                    </MenuItem>
                                                )
                                            })}

                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} md={4}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <InputLabel
                                            htmlFor="multiple-select"
                                            className={classes.selectLabel}
                                        >
                                            Sırala
                                        </InputLabel>
                                        <Select
                                            value={sortFilter}
                                            fullWidth
                                            onChange={e => setSortFilter(parseInt(e.target.value))}
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            inputProps={{
                                                name: "multipleSelect",
                                                id: "multiple-select"
                                            }}
                                        >
                                            {sortoptions.map(o => {
                                                return (
                                                    <MenuItem
                                                        value={o.value}
                                                        key={o.value}
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        {o.label}
                                                    </MenuItem>
                                                )
                                            })}

                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={6} md={4}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <InputLabel
                                            htmlFor="multiple-select"
                                            className={classes.selectLabel}
                                        >
                                            Paket
                                        </InputLabel>
                                        <Select
                                            value={_package}
                                            fullWidth
                                            onChange={e => _setPackage(e.target.value)}
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            inputProps={{
                                                name: "multipleSelect",
                                                id: "multiple-select"
                                            }}
                                        >
                                            <MenuItem
                                                value={"0"}
                                                classes={{
                                                    root: classes.selectMenuItem
                                                }}
                                            >
                                                Tümü
                                            </MenuItem>
                                            {packages.map(o => {
                                                return (
                                                    <MenuItem
                                                        value={o._id}
                                                        key={o._id}
                                                        classes={{
                                                            root: classes.selectMenuItem
                                                        }}
                                                    >
                                                        {o.name}
                                                    </MenuItem>
                                                )
                                            })}

                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridContainer xs={12} md={4} style={{ paddingRight: 10, paddingLeft: 10 }}>
                            <GridItem xs={5}>
                                <TextField
                                    fullWidth
                                    placeholder='Adı'
                                    style={{ marginTop: 20 }}
                                    value={first}
                                    onChange={e => setfirst(e.target.value)}
                                />
                            </GridItem>
                            <GridItem xs={5}>
                                <TextField
                                    fullWidth
                                    placeholder='Soyadı'
                                    style={{ marginTop: 20 }}
                                    value={family}
                                    onChange={e => setfamily(e.target.value)}
                                />
                            </GridItem>
                            <GridItem xs={2}>
                                <Button
                                    fullWidth
                                    style={{ marginTop: 20 }}
                                    color="primary"
                                    onClick={getData}
                                >ARA</Button>
                            </GridItem>
                        </GridContainer>
                    </GridContainer>
                    <PaginatedGrid
                        rows={rows()}
                        columns={columns}
                        defaultColumnWidths={defaultColumnWidths}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        total={total}
                    />
                </CardBody>
            </Card>
        </GridItem>
        <UserDialog
            open={userDialog}
            toggle={userDialogToggle}
            customer={customer}
        />
    </GridContainer>
)
}

export default Users