import Dashboard from "views/Dashboard/Dashboard.js";
import Anasayfa from "views/Dashboard/Anasayfa.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import TimelinePage from "views/Pages/Timeline.js";
import UserProfile from "views/Pages/UserProfile.js";

// Inner Pages
import MuscleGroups from 'views/Workouts/MuscleGroups'
import Movements from 'views/Workouts/Movements'
import Workouts from 'views/Workouts/Workouts'
import Workout from 'views/Workouts/Workout'
import FreeWorkout from 'views/Workouts/FreeWorkout'
import MuscleGroup from 'views/Workouts/MuscleGroup'
import Movement from 'views/Workouts/Movement'
import Users from "views/Users/Users";
import User from "views/Users/User";
import Subscriptions from 'views/Home/Subscriptions'
import Subscriptionsv2 from 'views/Home/Subscriptionsv2'
import Subscription from 'views/Home/Subscription'
import NutritionTable from 'views/Nutrition/NutritionTable'
import BulkNotification from 'views/Users/BulkNotification'

// @material-ui/icons
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store'
import Nutritions from "views/Nutrition/Nutritions";
import Nutrition from "views/Nutrition/Nutrition";
import Blogs from "views/Blog/Blogs";
import Blog from "views/Blog/Blog";
import Meals from "views/Nutrition/Meals";
import Meal from "views/Nutrition/Meal";
import Agreements from "views/Agreements/Agreements";
import Agreement from "views/Agreements/Agreement";
import Questions from "views/Questions/Questions";
import AllUsers from "views/Users/AllUsers";
import Coaches from "views/Coach/Coaches";
import Coach from "views/Coach/Coach";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import NoteIcon from '@material-ui/icons/Note';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

var dashRoutes = [
  // {
  //   collapse: true,
  //   name: "Anasayfa",
  //   icon: DashboardIcon,
  //   state: "dahboardCollapse",
  //   views: [
  //     {
  //       path: "/dashboard",
  //       name: "Anasayfa",
  //       mini: "SL",
  //       component: Anasayfa,
  //       layout: "/admin"
  //     },
  //     {
  //       name: "Blog Detay",
  //       icon: DashboardIcon,
  //       path: "/blog-detay",
  //       mini: "KU",
  //       component: Blog,
  //       layout: "/admin",
  //       hidden: true
  //     },
  //     {
  //       name: "Sözleşme Detay",
  //       icon: DashboardIcon,
  //       path: "/sozlesme-detay",
  //       mini: "KU",
  //       component: Agreement,
  //       layout: "/admin",
  //       hidden: true
  //     },
  //   ]
  // },
  {
    collapse: true,
    name: "Kullanıcılar",
    icon: AccountCircleIcon,
    state: "usersCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Anasayfa",
        mini: "SL",
        component: Users,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/aktif-kullanicilar",
        name: "Aktif Kullanıcılar",
        mini: "KU",
        icon: AccountCircleIcon,
        component: Users,
        layout: "/admin"
      },
      {
        path: "/tum-kullanicilar",
        name: "Tüm Kullanıcılar",
        mini: "KU",
        icon : PersonOutlineIcon,
        component: Users,
        layout: "/admin"
      },
      {
        path: "/kullanici-detay",
        name: "Kullanıcı Detay",
        mini: "KG",
        component: User,
        layout: "/admin",
        hidden: true
      },
      {
        name: "Sözleşme Detay",
        icon: DashboardIcon,
        path: "/sozlesme-detay",
        mini: "KU",
        component: Agreement,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/koc-detay",
        name: "Koç Detay",
        mini: "KG",
        component: Coach,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    collapse: false,
    name: "Koçlar",
    icon: SupervisedUserCircleIcon,
    path: "/koclar",
    mini: "KO",
    component: Coaches,
    layout: "/admin"
  },
  {
    collapse: false,
    name: "Toplu Bildirim",
    icon: SupervisedUserCircleIcon,
    path: "/toplu-bildirim",
    mini: "BI",
    component: BulkNotification,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Beslenme",
    icon: FastfoodIcon,
    state: "nutritionCollapse",
    views: [
      {
        path: "/besin-tablosu",
        name: "Besin Tablosu",
        mini: "BT",
        component: NutritionTable,
        layout: "/admin"
      },
      {
        path: "/ogunler",
        name: "Öğünler",
        mini: "BT",
        component: Meals,
        layout: "/admin"
      },
      {
        path: "/ogun-detay",
        name: "Öğün Detay",
        mini: "BT",
        component: Meal,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/beslenme-programlari",
        name: "Beslenme Programları",
        mini: "BT",
        component: Nutritions,
        layout: "/admin"
      },
      {
        path: "/beslenme-detay",
        name: "Beslenme Programı",
        mini: "BT",
        component: Nutrition,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    collapse: true,
    name: "Antrenmanlar",
    icon: FitnessCenterIcon,
    state: "workoutCollapse",
    views: [
      {
        path: "/kas-gruplari",
        name: "Kas Grupları",
        mini: "KG",
        component: MuscleGroups,
        layout: "/admin"
      },
      {
        path: "/kas-grubu",
        name: "Kas Grubu",
        mini: "KG",
        component: MuscleGroup,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/hareketler",
        name: "Hareketler",
        mini: "SL",
        component: Movements,
        layout: "/admin"
      },
      {
        path: "/hareket-duzenle",
        name: "Hareketler",
        mini: "SL",
        component: Movement,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/antrenmanlar",
        name: "Antrenmanlar",
        mini: "SL",
        component: Workouts,
        layout: "/admin"
      },
      {
        path: "/ucretsiz-antrenman",
        name: "Ücretsiz Antrenman",
        mini: "SL",
        component: FreeWorkout,
        layout: "/admin"
      },
      {
        path: "/antrenman-duzenle",
        name: "Antrenmanlar",
        mini: "SL",
        component: Workout,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    collapse: true,
    name: "İçerik",
    icon: DashboardIcon,
    state: "contentCollapse",
    views: [
      {
        path: "/paketler",
        name: "Paketler",
        mini: "PK",
        component: Subscriptions,
        layout: "/admin"
      },
      {
        path: "/paketlerv2",
        name: "Paketler-V2",
        mini: "PK",
        component: Subscriptionsv2,
        layout: "/admin"
      },
      {
        path: "/paket-detay",
        name: "Paket Detay",
        mini: "PK",
        component: Subscription,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/blog-detay",
        name: "Blog Detay",
        mini: "PK",
        component: Blog,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    collapse: false,
    name: "Blog",
    icon: DashboardIcon,
    path: "/blog-yazilari",
    mini: "KU",
    component: Blogs,
    layout: "/admin"
  },
  {
    collapse: false,
    name: "Sözleşmeler",
    icon: NoteIcon,
    path: "/sozlesmeler",
    mini: "KU",
    component: Agreements,
    layout: "/admin"
  },
  {
    collapse: false,
    name: "Sorular",
    icon: QuestionAnswerIcon,
    path: "/sorular",
    mini: "SO",
    component: Questions,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: StoreIcon,
    state: "pageCollapse",
    hidden: true,
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth"
      },
      {
        path: "/rtl-support-page",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: RTLSupport,
        layout: "/rtl"
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin"
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth"
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth"
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin"
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth"
      }
    ]
  },
];
export default dashRoutes;
