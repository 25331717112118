import React, { useEffect, useState } from 'react'
import { createBlog } from '_services/blog'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CardBody from 'components/Card/CardBody';
import Swal from 'sweetalert2';
import CustomInput from 'components/CustomInput/CustomInput';
import { getBlog } from '_services/blog';
import BlogPhoto from './BlogPhoto'

const Blog = props => {
    const [user] = useAtom(userAtom)
    const [title, setTitle] = useState('')
    const [meta, setMeta] = useState('')
    const [searchUrl, setSearchUrl] = useState('')
    const [tags, setTags] = useState([])
    const [_html, _setHtml] = useState(EditorState.createEmpty())
    const [photoUrl, setPhotoUrl] = useState("")
    const [id, setId] = useState("")
    const [mode, setMode] = useState(0)

    const { location } = props
    const { token } = user

    const [photoDialog, setPhotoDialog] = useState(false)

    const photoDialogToggle = () => {
        setPhotoDialog(!photoDialog)
    }


    useEffect(() => {
        if (location && location.blog && location.blog._id) {
            const { blog } = location
            setId(blog._id || '')
            setMode(1)
            setTitle(blog.title || '')
            setMeta(blog.meta || '')
            setSearchUrl(blog.searchUrl || '')
            setTags(blog.tags || [])
            setPhotoUrl(blog.photoUrl || '')
            const blocksFromHtml = htmlToDraft(blog._html || "<p></p>");
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            _setHtml(editorState)
        }
        return () => {
            setId('')
            setMode(0)
            setTitle('')
            setMeta('')
            setSearchUrl('')
            setTags([])
            setPhotoUrl("")
            _setHtml(EditorState.createEmpty())
        }
    }, [location])

    useEffect(() => {
        if (id && id !== "") {
            const data = { _id: id }
            getBlog(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        if (res.data.data[0]) {
                            const blocksFromHtml = htmlToDraft(res.data.data[0]._html || "<p></p>");
                            const { contentBlocks, entityMap } = blocksFromHtml;
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                            const editorState = EditorState.createWithContent(contentState);
                            _setHtml(editorState)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [id])

    const handleSubmit = () => {
        let data = { title, meta, searchUrl, tags, photoUrl, _html: draftToHtml(convertToRaw(_html.getCurrentContent())) }
        createBlog(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                    setMode(1)
                    setId(res.data.data._id)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Başarısız."
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Başarısız!"
                })
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Blog Detay</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={handleSubmit}> Blog {mode === 0 ? "Ekle" : "Düzenle"} </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Blog Başlığı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: title,
                                        onChange: (e) => setTitle(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Blog Url'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: searchUrl,
                                        onChange: (e) => setSearchUrl(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                {id !== '' &&
                                    <Button color='success' fullWidth onClick={photoDialogToggle} >Fotoğraf Yükle </Button>
                                }
                                {photoUrl !== '' &&
                                    <img src={photoUrl} alt={title} style={{ width: "90%", marginLeft : "5%" }} />
                                }
                            </GridItem>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText={'Meta Açıklama(' + meta.length + ') - 155 ile 160 karakter arası ideal'}
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: meta,
                                        onChange: (e) => setMeta(e.target.value),
                                        multiline: true,
                                        rows: 4
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <div style={{ minHeight: 200, marginTop: 30 }}>
                                    <Editor
                                        placeholder="Blog Yazısı Giriniz."
                                        toolbarOnFocus
                                        editorState={_html}
                                        toolbarClassName='toolbar'
                                        wrapperClassName='wrapper'
                                        editorClassName='editor'
                                        onEditorStateChange={(editorState) => _setHtml(editorState)}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                                            inline: { options: ['bold', 'italic', 'underline'] }
                                        }}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <BlogPhoto
                open={photoDialog}
                toggle={photoDialogToggle}
                id={id}
                token={token}
                setPhotoUrl={setPhotoUrl}
            />
        </GridContainer>
    )
}

export default Blog