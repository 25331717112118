import axios from 'axios';
import { domain } from './domain';

export const createMuscleGroup = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/musclegroup', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateMuscleGroup = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/musclegroup', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getMuscleGroups = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
        },
        data
    };
    return axios(domain + '/getmusclegroups', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const createMovement = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/movement', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateMovement = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/movement', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const uploadMovementVideo = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/movement-video', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getMovements = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
        },
        data
    };
    return axios(domain + '/getmovements', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const createWorkout = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/workout', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateWorkout = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/workout', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getWorkouts = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
        },
        data
    };
    return axios(domain + '/getworkouts', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getUserPrograms =  (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
        },
        data
    };
    return axios(domain + '/getuserprograms', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const createUserProgram =  (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/userprogram', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateUserProgram =  (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/userprogram', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const createUserWorkout =  (token,data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/userworkout', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateUserWorkout =  (token,data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/userworkout', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

