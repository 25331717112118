import React, { useState } from 'react';
import Button from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import WorkoutTypeAndLevel from 'views/Workouts/WorkoutTypeAndLevel';
import { getWorkouts } from '_services/workout';
import DataGrid from 'components/DataGrid/SimpleGrid';

const NewWorkoutDialog = (props) => {
    const [workoutType, setWorkoutType] = useState("")
    const [workoutLevel, setWorkoutLevel] = useState("")
    const [workoutSection, setWorkoutSection] = useState("")
    const [workouts, setWorkouts] = useState([])
    const [results, setResults] = useState(false)

    const { open, toggle, setNewUserWorkout, setName } = props

    const handleClose = (cancel) => {
        toggle(cancel)
    };

    const createUserWorkout = () => {

    }

    const handleGetWorkouts = () => {
        let data = {}
        if (workoutLevel !== '') {
            data = { ...data, workoutLevel }
        }
        if (workoutType !== '') {
            data = { ...data, workoutType }
        }
        if (workoutSection !== '') {
            data = { ...data, workoutSection }
        }
        getWorkouts(data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setWorkouts(res.data.data)
                    setResults(true)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const columns = [
        { name: 'name', title: "Antrenman Adı" },
        { name: 'workoutType', title: "Tipi" },
        { name: 'workoutLevel', title: "Seviye" },
        { name: 'workoutSections', title: "Aylar" },
        { name: 'movements', title: "Hareket Sayısı" },
        { name: 'action', title: "İşlem" }
    ];

    const rows = () => {
        return workouts.map((p) => ({
            name: p.name || '',
            workoutType: p.workoutType || "",
            workoutLevel: p.workoutLevel,
            workoutSections: p.workoutSection,
            movements: p.movements.length,
            action: <Button color='primary'  onClick={() => handleAdd(p)}>
                Ekle
            </Button> 
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 400 },
        { columnName: 'movements', width: 150 },
        { columnName: 'workoutType', width: 150 },
        { columnName: 'workoutLevel', width: 150 },
        { columnName: 'workoutSections', width: 150 },
        { columnName: 'action', width: 150 }
    ];

    const handleAdd = (workout) => {
        setName(workout.name)
        setNewUserWorkout(workout)
        toggle(false)
    }

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth='lg' fullWidth >
            <DialogTitle id="form-dialog-title">Antrenman Ekle</DialogTitle>
            <DialogContent >
                <GridContainer>
                    <WorkoutTypeAndLevel
                        workoutType={workoutType}
                        setWorkoutType={setWorkoutType}
                        workoutLevel={workoutLevel}
                        setWorkoutLevel={setWorkoutLevel}
                        workoutSection = {workoutSection}
                        setWorkoutSection = {setWorkoutSection}
                    />
                    <GridItem xs={2}>
                        <Button onClick={handleGetWorkouts} color="warning" block >
                            Ara
                        </Button>
                    </GridItem>
                    {results &&
                        <GridItem xs={12} style={{marginTop : 30}}>
                            <DataGrid
                                rows={rows()}
                                columns={columns}
                                defaultColumnWidths={defaultColumnWidths}
                            />
                        </GridItem>
                    }
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(true)} color="rose">
                    İptal
                </Button>
                <Button onClick={createUserWorkout} color="success">
                    Oluştur
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewWorkoutDialog