import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button'
import { useAtom } from "jotai"
import { userAtom } from "_atoms/user"
import Swal from 'sweetalert2'
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { getMuscleGroups, createMovement, updateMovement } from "_services/workout";
import Vimeo from '@u-wave/react-vimeo';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Divider, Tooltip, IconButton, LinearProgress } from "@material-ui/core"
import { Fragment } from "react"
import axios from "axios"
import ReactPlayer from "react-player"
import { uploadMovementVideo } from "_services/workout"

const useStyles = makeStyles(styles)

const Movement = props => {

    const [user] = useAtom(userAtom)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [tips, setTips] = useState([])
    const [forMale, setForMale] = useState(true)
    const [forFemale, setForFemale] = useState(true)
    const [videoId, setVideoId] = useState('')
    const [muscleGroups, setMuscleGroups] = useState([])
    const [tip, setTip] = useState("")
    const [thumbnail, setThumbnail] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [groups, setGroups] = useState([])
    const [ videoUrl, setVideoUrl ] = useState("")
    const [mode, setMode] = useState(0)
    const [id, setId] = useState('')

    const { location } = props

    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChangeVideo = event => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };

    const handleFile = (video) => {
        const fd = new FormData();
        fd.append('id', id)
        fd.append(('video'), video, video.name)
        submitOperation(fd)
    }

    const submitOperation = fd => {
        setLoading(true)
        uploadMovementVideo(token, fd)
            .then(res => {
                setLoading(false)
                if (res.data && res.data.code) {
                    setVideoUrl(res.data.data.videoUrl)
                    return Swal.fire({
                        icon: 'success',
                        title: 'İşlem Başarılı!',
                        timer: 2000,
                        showConfirmButton: false
                    })
                } else {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Bir hata oluştu.'
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                return Swal.fire({
                    icon: 'error',
                    title: 'Bir hata oluştu : ' + error
                })
            })
    }

    useEffect(() => {
        const data = { isActive: true }
        getMuscleGroups(data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setGroups(res.data.data)
                }
                else {
                    console.log(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (location && location.movement && location.movement._id) {
            const { movement } = location
            setMode(1)
            setId(movement._id || '')
            setName(movement.name || '')
            setDescription(movement.description || '')
            setForMale(movement.forMale || true)
            setTips(movement.tips || [])
            setForFemale(movement.forFemale || true)
            setMuscleGroups(movement.muscleGroups.map(m => m._id) || [])
            setVideoId(movement.videoId || '')
            setThumbnail(movement.thumbnail || '')
            setVideoUrl(movement.videoUrl || '')
        }
        return (() => {
            setMode(0)
            setId('')
            setName('')
            setDescription('')
            setForMale(true)
            setForFemale(true)
            setMuscleGroups([])
            setVideoId('')
            setThumbnail("")
            setVideoUrl("")
        })
    }, [location])

    const { token } = user

    const classes = useStyles()

    const handleAddMovement = () => {
        const data = { name, description, forFemale, forMale, videoId, muscleGroups, tips, thumbnail }
        // if (thumbnail === '') {
        //     Swal.fire({
        //         icon: "warning",
        //         title: "Video Linkini kontrol ediniz!"
        //     })
        //     return
        // }
        createMovement(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Hareket oluşturuldu."
                    })
                    props.history.goBack()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleUpdateMovement = () => {
        const data = { id, name, description, forFemale, forMale, videoId, muscleGroups, tips, thumbnail }
        if (thumbnail === "") {
            Swal.fire({
                icon: "warning",
                title: "Video Linkini kontrol ediniz!"
            })
            return
        }
        updateMovement(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Hareket Düzenlendi."
                    })
                    props.history.goBack()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleGroupId = id => {
        const index = muscleGroups.indexOf(id)
        if (index > -1) {
            setMuscleGroups([
                ...muscleGroups.slice(0, index),
                ...muscleGroups.slice(index + 1)
            ])
        } else {
            setMuscleGroups([
                ...muscleGroups,
                id
            ])
        }
    }

    const handleAddTip = () => {
        if (tip.length > 5) {
            setTips([
                ...tips,
                tip
            ])
            setTip("")
        } else {
            Swal.fire({
                icon: "warning",
                title: "Tavsiye çok kısa!"
            })
        }
    }


    const handleVideoId = e => {
        setThumbnail("")
        setVideoId(e.target.value)
        if (e.target.value.includes("vimeo.com/")) {
            axios.get(`https://vimeo.com/api/oembed.json?url=${e.target.value}`)
                .then(res => {
                    if (res.data && res.data.thumbnail_url_with_play_button) {
                        setThumbnail(res.data.thumbnail_url_with_play_button)
                    }
                })
        }
    }
    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}> Hareket {mode === 0 ? 'Ekle' : "Düzenle"} {id}</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Hareket Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: (e) => setName(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <GridContainer alignItems='flex-end'>
                                    <GridItem xs={6}>
                                        <div className={classes.checkboxAndRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => setForMale(!forMale)}
                                                        checked={forMale}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Erkeklere Uygun"
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <div className={classes.checkboxAndRadio}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        tabIndex={-1}
                                                        onClick={() => setForFemale(!forFemale)}
                                                        checked={forFemale}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        classes={{
                                                            checked: classes.checked,
                                                            root: classes.checkRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                    root: classes.labelRoot
                                                }}
                                                label="Kadınlara Uygun"
                                            />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <h6>Kas Grupları</h6>
                                <GridContainer>
                                    {groups.map(g => {
                                        return (
                                            <GridItem key={g._id}>
                                                <div className={classes.checkboxAndRadio}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                tabIndex={-1}
                                                                onClick={() => handleGroupId(g._id)}
                                                                checked={muscleGroups.includes(g._id)}
                                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                                icon={<Check className={classes.uncheckedIcon} />}
                                                                classes={{
                                                                    checked: classes.checked,
                                                                    root: classes.checkRoot
                                                                }}
                                                            />
                                                        }
                                                        classes={{
                                                            label: classes.label,
                                                            root: classes.labelRoot
                                                        }}
                                                        label={g.name}
                                                    />
                                                </div>
                                            </GridItem>
                                        )
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} style={{ marginBottom: 50 }}>
                                <h4>Tavsiyeler</h4>
                                <GridContainer alignItems='flex-end'>
                                    <GridItem xs={11}>
                                        <CustomInput
                                            labelText='Hareket Tavsiyesi'
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                value: tip,
                                                onChange: (e) => setTip(e.target.value),
                                                type: 'text',
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={1}>
                                        <Tooltip title='Tavsiye Ekle'>
                                            <IconButton onClick={handleAddTip}>
                                                <AddCircleOutlineIcon color='primary' size={30} />
                                            </IconButton>
                                        </Tooltip>
                                    </GridItem>
                                    {tips.map((t, i) => {
                                        return (
                                            <Fragment key={t + i}>
                                                <GridItem xs={11}>
                                                    <p style={{ fontWeight: "500", fontSize: 16 }}>{t}</p>
                                                </GridItem>
                                                <GridItem xs={1}>
                                                    <Tooltip title='Tavsiyeyi Sil'>
                                                        <IconButton onClick={() => {
                                                            setTips([
                                                                ...tips.slice(0, i),
                                                                ...tips.slice(i + 1)
                                                            ])
                                                        }}>
                                                            <HighlightOffIcon color='primary' size={30} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </GridItem>
                                                <Divider />
                                            </Fragment>
                                        )
                                    })}
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} md={6} style={{ height: 500 }}>
                                {loading
                                    ?   <LinearProgress />
                                    : <>
                                        <GridContainer>
                                            <GridItem xs={10}>
                                                <h5>{videoUrl}</h5>
                                            </GridItem>
                                            <GridItem xs={2}>
                                                <Button color="rose" onClick={handleClick} fullWidth>Yükle</Button>
                                                <input
                                                    type="file"
                                                    onChange={handleChangeVideo}
                                                    ref={hiddenFileInput}
                                                    style={{ display: 'none' }} // Make the file input element invisible
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        {videoId
                                            ? <Vimeo
                                                video={videoId}
                                                width={400}
                                                height={400}
                                            />
                                            : <ReactPlayer url={videoUrl} width={400} height={400} controls />
                                        }
                                    </>
                                }

                            </GridItem>
                            {thumbnail !== '' &&
                                <GridItem xs={12} md={6}>
                                    <img src={thumbnail} alt='thumb' style={{ height: 400, width: 400, objectFit: "contain" }} />
                                </GridItem>
                            }
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText='Hareket Açıklaması'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: description,
                                        multiline: true,
                                        rows: 4,
                                        onChange: (e) => setDescription(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={10}>

                            </GridItem>
                            <GridItem xs={12} md={2}>
                                {mode === 0
                                    ? <Button color='success' block onClick={handleAddMovement}>
                                        Ekle
                                        </Button>
                                    : <Button color='success' block onClick={handleUpdateMovement}>
                                        Düzenle
                                        </Button>
                                }

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Movement