import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState } from 'react'
import Button from 'components/CustomButtons/Button'
import { individualNotification } from '_services/customer'
import Swal from 'sweetalert2'

const Notification = props => {
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")

    const { fcmToken } = props.customer
    const { token } = props

    const handleSubmit = () => {
        const data = { fcmToken, title, body, user : props.customer._id }
        individualNotification(token,data)
        .then( res => {
            if(res.data && res.data.code === 200){
                Swal.fire({
                    icon : "success",
                    title : "Başarılı!"
                })
                setTitle("")
                setBody("")
            }else{
                Swal.fire({
                    icon : "danger",
                    title : "Başarısız!"
                })
            }
        })
        .catch( err => {
            Swal.fire({
                icon : "danger",
                title : "Başarısız!"
            })
        })
    }

    if (!fcmToken) {
        return (
            <div>
                Bu kullanıcı bildirimlere izin vermediği için bildirim gönderemiyoruz.
            </div>
        )
    }

    return (
        <GridContainer>
            <GridItem xs={12} md={4}>
                <CustomInput
                    labelText='Bildirim Başlığı'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: title,
                        onChange: (e) => setTitle(e.target.value),
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={8}>
                <CustomInput
                    labelText='Bildirim İçeriği'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: body,
                        onChange: (e) => setBody(e.target.value),
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={10}></GridItem>
            <GridItem xs={12} md={2}>
                <Button fullWidth color='primary' onClick={handleSubmit}> Gönder</Button>
            </GridItem>
        </GridContainer>
    )
}

export default Notification