import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState } from 'react'
import Button from 'components/CustomButtons/Button'
import { bulkNotification } from '_services/customer'
import Swal from 'sweetalert2'
import { useAtom } from 'jotai'
import { userAtom } from '_atoms/user'
import Card from 'components/Card/Card'
import { CardContent, LinearProgress } from '@material-ui/core'

const BulkNotification = () => {
    const [user] = useAtom(userAtom)
    const [title, setTitle] = useState("")
    const [body, setBody] = useState("")
    const [loading, setLoading] = useState(false)

    const { token } = user

    const handleSubmit = () => {
        const data = { title, body }
        setLoading(true)
        bulkNotification(token, data)
            .then(res => {
                setLoading(false)
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                    setTitle("")
                    setBody("")
                } else {
                    Swal.fire({
                        icon: "danger",
                        title: "Başarısız!"
                    })
                }
            })
            .catch(err => {
                setLoading(false)
                Swal.fire({
                    icon: "danger",
                    title: "Başarısız!"
                })
            })
    }

    return (
        <Card>
            <CardContent>
                <GridContainer>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText='Bildirim Başlığı'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: title,
                                onChange: (e) => setTitle(e.target.value),
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={8}>
                        <CustomInput
                            labelText='Bildirim İçeriği'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: body,
                                onChange: (e) => setBody(e.target.value),
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={10}></GridItem>
                    <GridItem xs={12} md={2}>
                        {loading
                            ? <LinearProgress />
                            : <Button fullWidth color='primary' onClick={handleSubmit}> Gönder</Button>
                        }

                    </GridItem>
                </GridContainer>
            </CardContent>
        </Card>
    )
}

export default BulkNotification