import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createFitnessPackage, updateFitnessPackage } from '_services/subscription'
import Swal from 'sweetalert2';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from 'components/CustomInput/CustomInput.js';
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import Button from 'components/CustomButtons/Button'
import CardIcon from 'components/Card/CardIcon';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment'
import 'moment/locale/tr'
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Vimeo from '@u-wave/react-vimeo';
import BannerDialog from './BannerDialog';
import WebsitePhotoDialog from './WebsitePhotodialog';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player'
import { uploadPackageVideo } from '_services/subscription';
import { LinearProgress } from '@material-ui/core';

const styles2 = {
    wrapper: {
        backgroundColor: "blue"
    },
    editor: {
        backgroundColor: "pink"
    },
    toolbar: {
        backgroundColor: "pink"
    },
    switchBase: {
        color: "pink"
    }
}

const useStyles = makeStyles({ ...styles, styles2 })

const Subscription = props => {
    const [subscription, setSubscription] = useState({})
    const [user] = useAtom(userAtom)
    const { token } = user
    const [name, setName] = useState('')
    const [packageType, setPackageType] = useState("Tek Ödeme")
    const { location } = props
    const [mode, setMode] = useState(0)
    const [lastApplication, setLastApplication] = useState(moment().add(1, "month").format('YYYY-MM-DD'))
    const [contentState, setContentState] = useState(EditorState.createEmpty())
    const [bannerUrl, setBannerUrl] = useState("")
    const [websitePhoto, setWebsitePhoto] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [isActive, setIsActive] = useState(true)
    const [pricingPlanReferenceCode, setPricingPlanReferenceCode] = useState("")
    const [prices, setPrices] = useState([])
    const [pname, setpname] = useState("")
    const [days, setDays] = useState("")
    const [price, setPrice] = useState("")
    const [loading, setLoading] = useState(false)
    const [bannerDialog, setBannerDialog] = useState(false)
    const [websitePhotoDialog, setWebsitePhotoDialog] = useState(false)
    const [id, setId] = useState("")
    const classes = useStyles()

    const handleBannerDialogToggle = () => {
        setBannerDialog(!bannerDialog)
    }

    const handleWebsitePhotoDialogToggle = () => {
        setWebsitePhotoDialog(!websitePhotoDialog)
    }

    const hiddenFileInput = useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChangeVideo = event => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };

    const handleFile = (video) => {
        const fd = new FormData();
        fd.append('id', id)
        fd.append(('video'), video, video.name)
        submitOperation(fd)
    }

    const submitOperation = fd => {
        setLoading(true)
        uploadPackageVideo(token, fd)
            .then(res => {
                setLoading(false)
                if (res.data && res.data.code) {
                    setVideoUrl(res.data.data.videoUrl)
                    return Swal.fire({
                        icon: 'success',
                        title: 'İşlem Başarılı!',
                        timer: 2000,
                        showConfirmButton: false
                    })
                } else {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Bir hata oluştu.'
                    })
                }
            })
            .catch(error => {
                setLoading(false)
                return Swal.fire({
                    icon: 'error',
                    title: 'Bir hata oluştu : ' + error
                })
            })
    }

    useEffect(() => {
        if (location && location.subs) {
            const { subs } = location
            setSubscription(location.subs)
            setId(subs._id)
            setName(subs.name || '')
            setPackageType(subs.packageType || '')
            setLastApplication(subs.lastApplication || moment().add(1, "month").format('YYYY-MM-DD'))
            setMode(1)
            setPrice(subs.price || "")
            setBannerUrl(subs.bannerUrl || '')
            setWebsitePhoto(subs.websitePhoto || '')
            setVideoUrl(subs.videoUrl || '')
            setStartDate(subs.startDate || '')
            setEndDate(subs.endDate || '')
            setIsActive(subs.isActive)
            setPrices(subs.prices || [])
            setPricingPlanReferenceCode(subs.pricingPlanReferenceCode || "")
            const blocksFromHtml = htmlToDraft(subs.description || "<p></p>");
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setContentState(editorState)
        }
        return () => {
            setSubscription({})
            setName('')
            setPackageType('Tek Ödeme')
            setLastApplication("")
            setMode(0)
            setPrice("")
            setBannerUrl('')
            setWebsitePhoto('')
            setVideoUrl('')
            setStartDate('')
            setEndDate('')
            setIsActive(true)
        }
    }, [location])

    const handleAddPackage = () => {
        if (name === '') {
            Swal.fire({
                icon: "info",
                title: "Lütfen paket adını giriniz."
            })
            return
        }
        if (packageType === '') {
            Swal.fire({
                icon: "info",
                title: "Lütfen paket tipini seçiniz."
            })
            return
        }
        const data = { packageType, name, order: 100, isV2: true, prices: prices.sort((a, b) => a.days < b.days ? -1 : 1), websitePhoto, lastApplication, pricingPlanReferenceCode, price, bannerUrl, videoUrl, startDate, endDate, isActive, description: draftToHtml(convertToRaw(contentState.getCurrentContent())) }
        createFitnessPackage(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    if (res.data && res.data.code === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Paket Eklendi!"
                        })
                        setMode(1)
                        setSubscription(res.data.data)
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Paket Eklenemedi"
                        })
                    }
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Paket Eklenemedi!"
                })
                console.log(err)
            })
    }

    const handleUpdatePackage = () => {
        if (name === '') {
            Swal.fire({
                icon: "info",
                title: "Lütfen paket adını giriniz."
            })
            return
        }
        if (packageType === '') {
            Swal.fire({
                icon: "info",
                title: "Lütfen paket tipini seçiniz."
            })
            return
        }
        const data = { id: subscription._id, name, websitePhoto, prices: prices.sort((a, b) => a.days < b.days ? -1 : 1), packageType, pricingPlanReferenceCode, lastApplication, price, bannerUrl, videoUrl, startDate, endDate, isActive, description: draftToHtml(convertToRaw(contentState.getCurrentContent())) }
        updateFitnessPackage(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Paket Düzenlendi!"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Paket Düzenlenemedi"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Paket Düzenlenemedi!"
                })
                console.log(err)
            })
    }

    const onEditorStateChange = (editorState) => {
        setContentState(editorState)
    }

    const handleAddPrices = () => {
        let data = { name: pname, days: parseInt(days), sku: uuidv4().replaceAll("-", ""), price: parseFloat(price) }

        setPrices([
            ...prices,
            data
        ])
        setPrice("")
        setDays("")
        setpname("")
    }

    const dayResolver = d => {
        switch (d) {
            case 2:
                return "2 Gün"
            case 4:
                return "4 Gün"
            case 5:
                return "5 Gün"
            case 7:
                return "1 Hafta"
            case 30:
                return "1 Ay"
            case 45:
                return "45 Gün"
            case 61:
                return "2 Ay"
            case 92:
                return "3 Ay"
            case 123:
                return "4 Ay"
            case 184:
                return "6 Ay"
            case 275:
                return "9 Ay"
            case 365:
                return "1 Yıl"
            default:
                return "Bilinmiyor"
        }
    }


    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}>Paket {mode === 0 ? "Ekle" : "Düzenle"}</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <GridContainer alignItems='center'>
                            <GridItem xs={12}>
                                <CustomInput
                                    labelText='Paket Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: (e) => setName(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <h3>Süreli Paketler</h3>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={12} md={11}>
                                        <GridContainer>
                                            <GridItem xs={6} md={3}>
                                                <CustomInput
                                                    labelText='Alt Paket'
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    placeholder="Alt Paket Adını giriniz"
                                                    disabled
                                                    inputProps={{
                                                        value: pname,
                                                        onChange: (e) => setpname(e.target.value),
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} md={3}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                    style={{ marginBottom: "-100px !important" }}
                                                >
                                                    <InputLabel
                                                        htmlFor="multiple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Paket Süresi
                                                    </InputLabel>
                                                    <Select
                                                        value={days}
                                                        fullWidth
                                                        onChange={e => setDays(e.target.value)}
                                                        MenuProps={{ className: classes.selectMenu }}
                                                        classes={{ select: classes.select }}
                                                        inputProps={{
                                                            name: "multipleSelect",
                                                            id: "multiple-select"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Seçiniz
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='2'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            2 Gün
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='4'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            4 Gün
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='5'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            5 Gün
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='7'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            1 Hafta
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='30'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            1 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='45'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            45 Gün
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='61'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            2 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='92'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            3 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='123'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            4 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='184'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            6 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='275'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            9 Ay
                                                        </MenuItem>
                                                        <MenuItem
                                                            value='365'
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            1 Yıl
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={6} md={3}>
                                                <CustomInput
                                                    labelText='Paket Barkodu Otomatik Atanacak'
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    placeholder="Otomatik Atanacak"
                                                    disabled
                                                    inputProps={{
                                                        type: "number",
                                                        disabled: true,
                                                        value: "",
                                                        onChange: (e) => setPrice(e.target.value),
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} md={3}>
                                                <CustomInput
                                                    labelText='Paket Ücreti'
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        type: "number",
                                                        value: price,
                                                        onChange: (e) => setPrice(e.target.value),
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} md={1}>
                                        <Button color='success' onClick={() => {
                                            handleAddPrices()
                                        }} >Ekle</Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            {prices.map((p, index) => {
                                return (
                                    <GridContainer key={p.days}>
                                        <GridItem xs={12} md={11}>
                                            <GridContainer>
                                                <GridItem xs={12} md={3}>
                                                    <span style={{ fontWeight: "600", marginLeft: 30 }}>{p.name}</span>
                                                </GridItem>
                                                <GridItem xs={12} md={3}>
                                                    <span style={{ fontWeight: "600", marginLeft: 30 }}>{dayResolver(p.days)}</span>
                                                </GridItem>
                                                <GridItem xs={6} md={3}>
                                                    <span style={{ fontWeight: "600" }}>{p.sku}</span>
                                                </GridItem>
                                                <GridItem xs={12} md={3}>
                                                    <span style={{ fontWeight: "600" }}>{p.price.toFixed(2)}₺</span>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12} md={1}>
                                            <Button color='danger' onClick={() => {
                                                setPrices([
                                                    ...prices.slice(0, index),
                                                    ...prices.slice(index + 1)
                                                ])
                                            }} >Sil</Button>
                                        </GridItem>
                                    </GridContainer>
                                )
                            })}
                            {/* <GridItem xs={6} md={3}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                    style={{ marginBottom: 10 }}
                                >
                                    <InputLabel
                                        htmlFor="multiple-select"
                                        className={classes.selectLabel}
                                    >
                                        Paket Tipi
                                    </InputLabel>
                                    <Select
                                        value={packageType}
                                        fullWidth
                                        disabled
                                        onChange={e => setPackageType(e.target.value)}
                                        MenuProps={{ className: classes.selectMenu }}
                                        classes={{ select: classes.select }}
                                        inputProps={{
                                            name: "multipleSelect",
                                            id: "multiple-select"
                                        }}
                                    >
                                        <MenuItem
                                            value=''
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Seçiniz
                                        </MenuItem>
                                        <MenuItem
                                            value='Abonelik'
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Abonelik
                                        </MenuItem>
                                        <MenuItem
                                            value='Tek Ödeme'
                                            classes={{
                                                root: classes.selectMenuItem
                                            }}
                                        >
                                            Tek Ödeme
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={6} md={3}>
                                Paket aktif mi :
                                <span style={{ marginLeft: 30 }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isActive}
                                                onChange={event => setIsActive(!isActive)}
                                                color='secondary'
                                                value="checkedB"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label
                                        }}
                                        label={isActive ? "Evet" : "Hayır"}
                                    />
                                </span>
                            </GridItem>
                            <GridItem xs={6} md={3}>
                                <CustomInput
                                    labelText='Paket Ücreti'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: "number",
                                        value: price,
                                        onChange: (e) => setPrice(e.target.value),
                                    }}
                                />
                            </GridItem>
                             <GridItem xs={6} md={3}>
                                {packageType === 'Abonelik'
                                    ? "Son Başvuru : Süresiz"
                                    : <DatePicker
                                        label="Son Başvuru"
                                        value={lastApplication || moment().add(1, "month").format('YYYY-MM-DD')}
                                        onChange={date => setLastApplication(moment(date).format('YYYY-MM-DD'))}
                                        format='DD MMMM YYYY'
                                        okLabel='TAMAM'
                                        cancelLabel='KAPAT'
                                        fullWidth
                                    />
                                }
                            </GridItem>
                            <GridItem xs={6} md={3}>
                                {packageType === 'Abonelik'
                                    ? "Başlangıç Tarihi : Süresiz"
                                    : <DatePicker
                                        label="Başlangıç Tarihi"
                                        value={startDate || moment().add(1, "month").format('YYYY-MM-DD')}
                                        onChange={date => setStartDate(moment(date).format('YYYY-MM-DD'))}
                                        format='DD MMMM YYYY'
                                        okLabel='TAMAM'
                                        cancelLabel='KAPAT'
                                        fullWidth
                                    />
                                }
                            </GridItem>
                            <GridItem xs={6} md={3}>
                                {packageType === 'Abonelik'
                                    ? "Bitiş Tarihi : Süresiz"
                                    : <DatePicker
                                        label="Bitiş Tarihi"
                                        value={endDate || moment().add(1, "month").format('YYYY-MM-DD')}
                                        onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
                                        format='DD MMMM YYYY'
                                        okLabel='TAMAM'
                                        cancelLabel='KAPAT'
                                        fullWidth
                                    />
                                }
                            </GridItem> */}
                            {/* <GridItem xs={6}>
                                <CustomInput
                                    labelText='Paket Kodu'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: pricingPlanReferenceCode,
                                        onChange: (e) => setPricingPlanReferenceCode(e.target.value),
                                    }}
                                />
                            </GridItem> */}
                            <GridItem xs={12}>
                                <h3>İçerik Detayı</h3>
                            </GridItem>
                            <GridItem xs={12} md={6} style={{ height: 500 }}>
                                {mode === 1 &&
                                    <div style={{ marginTop: 15, marginBottom: 20 }}>
                                        <Button color='success' block onClick={handleBannerDialogToggle} >Banner Fotoğrafı Yükle</Button>
                                    </div>
                                }
                                {bannerUrl &&
                                    <img
                                        src={bannerUrl}
                                        width={400}
                                        height={200}
                                        alt={name}
                                    />
                                }
                            </GridItem>
                            <GridItem xs={12} md={6} style={{ height: 500 }}>
                                {loading
                                    ? <LinearProgress />
                                    : <>
                                        <GridContainer>
                                            <GridItem xs={10}>
                                                <h5>{videoUrl}</h5>
                                            </GridItem>
                                            <GridItem xs={2}>
                                                <Button color="rose" onClick={handleClick} fullWidth>Yükle</Button>
                                                <input
                                                    type="file"
                                                    onChange={handleChangeVideo}
                                                    ref={hiddenFileInput}
                                                    style={{ display: 'none' }} // Make the file input element invisible
                                                />
                                            </GridItem>
                                        </GridContainer>
                                        {videoUrl && videoUrl.includes("vimeo")
                                            ? <Vimeo
                                                video={videoUrl}
                                                width={400}
                                                height={400}
                                            />
                                            : <ReactPlayer url={videoUrl} width={400}
                                                height={400} controls />
                                        }
                                    </>
                                }

                            </GridItem>
                            <GridItem xs={12} md={6}>
                                {mode === 1 &&
                                    <div style={{ marginTop: 15, marginBottom: 20 }}>
                                        <Button color='success' block onClick={handleWebsitePhotoDialogToggle} >Website Fotoğrafı Yükle</Button>
                                    </div>
                                }
                                {websitePhoto &&
                                    <img
                                        src={websitePhoto}
                                        style={{ width: "100%", }}
                                        alt={name}
                                    />
                                }
                            </GridItem>
                            <GridItem xs={12}>
                                <div style={{ minHeight: 200, marginTop: 30 }}>
                                    <Editor
                                        placeholder="Açıklama giriniz."
                                        toolbarOnFocus
                                        editorState={contentState}
                                        toolbarClassName={classes.toolbar}
                                        wrapperClassName={classes.wrapper}
                                        editorClassName={classes.editor}
                                        onEditorStateChange={(editorState) => onEditorStateChange(editorState)}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'history'],
                                            inline: { options: ['bold', 'italic', 'underline'] }
                                        }}
                                    />
                                </div>
                            </GridItem>
                            <GridItem xs={6} md={9}></GridItem>
                            <GridItem xs={6} md={3}>
                                {mode === 0
                                    ? <Button color='success' block style={{ float: "right" }} onClick={handleAddPackage}> Oluştur </Button>
                                    : <Button color='success' block style={{ float: "right" }} onClick={handleUpdatePackage}> Düzenle </Button>
                                }
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <BannerDialog
                token={token}
                id={subscription._id}
                open={bannerDialog}
                toggle={handleBannerDialogToggle}
                setBannerUrl={setBannerUrl}
            />
            <WebsitePhotoDialog
                token={token}
                id={subscription._id}
                open={websitePhotoDialog}
                toggle={handleWebsitePhotoDialogToggle}
                setBannerUrl={setWebsitePhoto}
            />
        </GridContainer>

    )
}

export default Subscription