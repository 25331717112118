import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import CardBody from 'components/Card/CardBody';
import Swal from 'sweetalert2';
import CustomInput from 'components/CustomInput/CustomInput';
import { getFitnessPackagesV2 } from '_services/subscription';
import { coachRegister, coachUpdate } from '_services/coach'
import Select from 'react-select'

const Coach = props => {
    const [user] = useAtom(userAtom)

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [questionEnum, setQuestionEnum] = useState(0)
    const [_package, _setPackage] = useState("")
    const [_packageValue, _setPackageValue] = useState({ value: "", label: "Paket Seçiniz" })
    const [ questionValue, setQuestionValue ] = useState({ value: "", label: "Soru Başlığı Seçiniz" })
    const [_packages, _setPackages] = useState([])

    const [coaches, setCoaches] = useState([])
    const [coachIndex, setCoachIndex] = useState(0)
    const [id, setId] = useState("")
    const [mode, setMode] = useState(0)

    const { location } = props
    const { token } = user

    const questions = [
        {
            value : 0,
            label : "Genel Admin"           
        },
        {
            value : 1,
            label : "Bilgehan Koç"           
        },
        {
            value : 2,
            label : "Mpfit Uzmanı"           
        },
        {
            value : 3,
            label : "Spor Beslenme Uzmanı"           
        },
        {
            value : 4,
            label : "Anabolik Uzmanı"           
        },
        {
            value : 5,
            label : "Doktor"           
        },
        {
            value : 6,
            label : "Spor Psikoloğu"           
        },
    ]

    useEffect(() => {
        getFitnessPackagesV2()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    let packageOptions = []
                    for (let i = 0; i < res.data.data.length; i++) {
                        const opt = {
                            label: res.data.data[i].name,
                            value: res.data.data[i]._id
                        }
                        packageOptions = [
                            ...packageOptions,
                            opt
                        ]
                    }
                    _setPackages(packageOptions)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (_package !== "" && _packages.length > 0) {
            let index = _packages.map(p => p.value).indexOf(_package)
            if (index > -1) {
                _setPackageValue(_packages[index])
            }
        }
    }, [_packages, _package])

    useEffect(() => {
        if(questionEnum > -1){
            let index = questions.map(p => p.value).indexOf(questionEnum)
            if (index > -1) {
                setQuestionValue(questions[index])
            }
        }
    },[questionEnum])

    useEffect(() => {
        if (location && location.coaches) {
            setCoaches(location.coaches)
            setCoachIndex(location.coachIndex || 0)
        }
        return () => {
            setId('')
            setMode(0)
        }
    }, [location])

    useEffect(() => {
        if (coaches.length > 0) {
            const coach = coaches[coachIndex]
            setId(coach._id || '')
            setMode(1)
            setName(coach.name || "")
            setSurname(coach.surname || "")
            _setPackage(coach._package || "")
            setUsername(coach.username || "")
            setPhone(coach.phone || "")
            setQuestionEnum(coach.questionEnum || 0)
        }
    }, [coachIndex, coaches])

    const handleSubmit = () => {
        const data = { name, surname, username, password, questionEnum, _package, phone }
        coachRegister(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setMode(1)
                    setId(res.data.data._id)
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Başarısız"
                    })
                }
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    icon: "error",
                    title: "Başarısız"
                })
            })
    }

    const handleEditSubmit = () => {
        const data = { id, name, surname, username, password, questionEnum, _package, phone }
        coachUpdate(token, data)
            .then(res => {
                console.log(res.data)
                if (res.data && res.data.code === 200) {
                    Swal.fire({
                        icon: "success",
                        title: "Başarılı!"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Başarısız"
                    })
                }
            })
            .catch(err => {
                console.log(err)
                Swal.fire({
                    icon: "error",
                    title: "Başarısız"
                })
            })
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Koç Detay</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Button color='success' style={{ float: "right" }} onClick={mode === 0 ? handleSubmit : handleEditSubmit}> Koç {mode === 0 ? "Ekle" : "Düzenle"} </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <GridContainer alignItems='center'>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: name,
                                        onChange: (e) => setName(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Soyadı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: surname,
                                        onChange: (e) => setSurname(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Kullanıcı Adı'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: username,
                                        onChange: (e) => setUsername(e.target.value),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                {mode === 0 &&
                                    <CustomInput
                                        labelText='Şifre'
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            value: password,
                                            onChange: (e) => setPassword(e.target.value),
                                        }}
                                    />
                                }
                            </GridItem>
                            <GridItem xs={12}>
                                <h4>Paket ve Soru Başlığı</h4>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <Select style={{ marginTop: 27 }} options={[{ value: "", label: "Paket Seçiniz" }, ..._packages]} value={_packageValue || ""} placeholder="Yönettiği Paket" onChange={(e) => {
                                    _setPackage(e.value)
                                }}
                                    noOptionsMessage={() => <div> Seçenek Bulunamadı </div>}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <Select style={{ marginTop: 27 }} options={[{ value: "", label: "Soru Başlığı Seçiniz" }, ...questions]} value={questionValue || ""} placeholder="Soru Başlığı" onChange={(e) => {
                                    setQuestionEnum(e.value)
                                }}
                                    noOptionsMessage={() => <div> Seçenek Bulunamadı </div>}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <CustomInput
                                    labelText='Telefon'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: phone,
                                        onChange: (e) => setPhone(e.target.value),
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Coach