import React, { useEffect, useState } from 'react'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import NavPills from "components/NavPills/NavPills.js";
import GeneralInfo from './GeneralInfo'
import Workouts from './Workouts'
import Nutrition from './Nutrition'
import Measurements from './Measurements'
import Sorular from './Sorular'
import Notification from './Notification'
import { useAtom } from 'jotai'
import { userAtom } from '_atoms/user'
import { getCustomerById } from '_services/customer'
import Subscription from './Subscription'

const User = props => {
    const [user] = useAtom(userAtom)
    const [id, setId] = useState('')
    const [customer, setCustomer] = useState({})
    const { location, noHeader } = props
    const { token } = user

    useEffect(() => {
        if (location && location.id) {
            setId(location.id)
        }
    }, [location])

    useEffect(() => {
        if (props.customer && props.customer._id) {
            setId(props.customer._id)
        }
    }, [props.customer])

    useEffect(() => {
        if (id !== '') {
            const data = { id }
            getCustomerById(token, data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setCustomer(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [id])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    {!noHeader &&
                        <CardHeader>
                            <CardIcon color='rose'>
                                <h4 style={{ color: "#fff" }}> Kullanıcı Detayı</h4>
                            </CardIcon>
                        </CardHeader>
                    }
                    <CardBody>
                        <NavPills
                            color="rose"
                            tabs={[
                                {
                                    tabButton: "Genel Bilgiler",
                                    tabContent: <GeneralInfo id={id} token={token} customer={customer} setCustomer={setCustomer} />
                                },
                                {
                                    tabButton: "Üyelik Bilgileri",
                                    tabContent: <Subscription id={id} token={token} customer={customer} setCustomer={setCustomer} />
                                },
                                {
                                    tabButton: "Programlar",
                                    tabContent: <Workouts id={id} token={token} customer={customer} setCustomer={setCustomer} />
                                },
                                {
                                    tabButton: "Beslenme",
                                    tabContent: <Nutrition id={id} token={token} customer={customer} />
                                },
                                {
                                    tabButton: "Ölçümler",
                                    tabContent: <Measurements id={id} token={token} />
                                },
                                {
                                    tabButton: "Sorular",
                                    tabContent: <Sorular id={id} token={token} customer={customer} />
                                },
                                {
                                    tabButton: "Bildirim",
                                    tabContent: <Notification customer={customer} token={token} />
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default User