import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import { getMeals } from '_services/food';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';


const Meals = props => {
    const [ms, setMs] = useState([])
    const [filter, setFilter] = useState("Tümü")
    useEffect(() => {
        getMeals()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setMs(res.data.data)
                }
            })
            .catch(err => console.log(err))
    }, [])

    const filters = ["Tümü", "Kilo Alma", "Kilo Verme", "Hipertrofi", "Vegan", "Vejeteryan", "Suplement", "Kuruyemiş"]

    let filteredMS = ms

    if (filter !== 'Tümü') {
        filteredMS = filteredMS.filter(m => m.group === filter)
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Öğünler</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/ogun-detay'>
                                    <Button color='success' style={{ float: "right" }}> Öğün Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                {filters.map(m => {
                    return (
                        <Button color={filter === m ? 'success' : "info"} key={m} onClick={() => setFilter(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                    )
                })}
            </GridItem>
            { filteredMS.map(n => {
                return (
                    <GridItem xs={12} md={4} lg={3} key={n._id}>
                        <Card>
                            <CardHeader>
                                <GridContainer>
                                    <GridItem xs={12} md={8}>
                                        <h4 style={{ color: "#000", fontWeight: "500" }}>{n.name} - ({n.totalCalori}Kkal)</h4>
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <Link to={{ pathname: '/admin/ogun-detay', meal: n }}>
                                            <IconButton style={{ float: "right" }}>
                                                <EditIcon color='primary' />
                                            </IconButton>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                        </Card>
                    </GridItem>
                )
            })}
        </GridContainer>
    )
}

export default Meals