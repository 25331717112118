import { CardContent } from '@material-ui/core'
import Card from 'components/Card/Card'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getCustomerById } from '_services/customer'

const image = require('../../assets/img/placeholder.jpg')

const GeneralInfo = props => {
    const { customer } = props

    const arrResolve = arr => {
        let text = ''
        for (let i = 0; i < arr.length; i++) {
            if (i === arr.length - 1) {
                text += arr[i]
            } else {
                text += arr[i] + ", "
            }
        }
        return text
    }

    return (
        <GridItem xs={12}>
            <GridContainer>
                <GridItem xs={12} md={3}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", border: "1px solid lightgray", borderRadius: 8 }}>
                        <img src={customer.avatar ? customer.avatar : image} alt="user" style={{ height: 250, width: 250, aspectRatio: 1, objectFit: "cover", borderRadius: 250, marginTop: 50 }} />
                        <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 0, marginTop: 15 }}>{customer.name ? (customer.name.first + ' ' + customer.name.family) : "İsim Eklememiş"}</p>
                        <p style={{ fontSize: 14, fontWeight: "400", marginBottom: 0, color: "gray" }}>{customer.email}</p>
                        <p style={{ fontWeight: "bold" }}> Son Giriş  : {customer.lastLogIn ? (moment(customer.lastLogIn).fromNow()) : "Bilinmiyor"}</p>
                    </div>
                </GridItem>
                <GridItem xs={12} md={9}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 20, flexDirection: "column", border: "1px solid lightgray", borderRadius: 8 }}>
                        <GridContainer>
                            <GridItem xs={12} md={6}>
                                <GridContainer>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Telefon  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.phone}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> İşletim Sistemi  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.os ? (customer.os === "ios" ? "IOS" : "ANDROID") : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Cinsiyet  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.gender ? (customer.gender === "male" ? "Erkek" : "Kadın") : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Doğum Günü  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.birthday ? moment(customer.birthday).format("DD MMMM YYYY") : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Kan Grubu : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.bloodType ? (customer.bloodType) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Boy  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.height ? (customer.height + ' cm') : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Yaşam Tarzı  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.lifeStyle ? (customer.lifeStyle) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Su alışkanlığı  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.water ? (customer.water) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Kilosu : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.weight ? (customer.weight + ' kg') : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Günlük Uyku  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.sleep ? ((customer.sleep)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <GridContainer>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Spor Mekanı  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.place ? ((customer.place)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Vücut Tipi   : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.bodyType ? (customer.bodyType) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Spora Ayıracağı Süre : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.dailyTime ? (customer.dailyTime) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Odak : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.focusOn ? (arrResolve(customer.focusOn)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Spor Yapma Amacı  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.goal ? (arrResolve(customer.goal)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Alışkanlıklar : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.habits ? (arrResolve(customer.habits)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Rahatsızlıklar  : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.illness ? (arrResolve(customer.illness)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                    <GridItem xs={6} style={{ float: "right", marginBottom: 20 }}>
                                        <span style={{ fontWeight: "bold", marginBottom: 20 }}> Beslenme : </span>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <span style={{ marginBottom: 20 }}> {customer.nutrition ? (arrResolve(customer.nutrition)) : "Bilinmiyor"}</span>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
        </GridItem>
    )
}

export default GeneralInfo