import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useEffect, useState } from 'react'
import CustomInput from 'components/CustomInput/CustomInput'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Button from 'components/CustomButtons/Button'
import { Divider, Tooltip, IconButton, Typography } from "@material-ui/core"
import { Fragment } from "react"
import moment from 'moment'
import 'moment/locale/tr'
import Swal from 'sweetalert2'
import { updateUserProgram, updateUserWorkout } from '_services/workout'
import Vimeo from '@u-wave/react-vimeo';
import { DatePicker } from '@material-ui/pickers';
import AddMovementDialog from 'views/Workouts/AddMovementDialog';
import ReactPlayer from 'react-player';

const Program = props => {
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState("")
    const [tips, setTips] = useState([])
    const [workouts, setWorkouts] = useState([])
    const [description, setDescription] = useState('')

    const [restHours, setRestHours] = useState("")
    const [movementObjects, setMovementObjects] = useState([])
    const [movementInfo, setMovementInfo] = useState([])
    const [workoutId, setWorkoutId] = useState("")
    const [movementDialog, setMovementDialog] = useState(false)

    const [tip, setTip] = useState("")

    const { program, setProgram, token } = props

    const handleMovementDialogToggle = () => {
        setMovementDialog(!movementDialog)
    }

    useEffect(() => {
        if (program._id) {
            setName(program.name || '')
            setStartDate(program.startDate || '')
            setEndDate(program.endDate || '')
            setTips(program.tips || [])
            setWorkouts(program.workouts || [])
            setDescription(program.description || '')
        }
    }, [program])

    useEffect(() => {
        return () => {
            setName('')
            setStartDate('')
            setEndDate('')
            setTips([])
            setWorkouts([])
            setDescription('')
        }
    }, [])

    const handleAddTip = () => {
        if (tip.length > 5) {
            setTips([
                ...tips,
                tip
            ])
            setTip("")
        } else {
            Swal.fire({
                icon: "warning",
                title: "Tavsiye çok kısa!"
            })
        }
    }

    const handleUpdate = () => {
        const data = { id: program._id, tips, endDate: moment(endDate).toDate(), description, workouts: workouts.map(w => w._id) }
        updateUserProgram(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setProgram(res.data.data)
                    Swal.fire({
                        icon: "success",
                        title: "Program Düzenlendi"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Bir hata oluştu"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
            })
    }

    const handleSaveUserWorkout = () => {
        const data = { id: workoutId, movements: movementObjects, movementInfo, restHours }
        updateUserWorkout(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    const index = workouts.map(w => w._id).indexOf(res.data.data._id)
                    setWorkouts([
                        ...workouts.slice(0, index),
                        res.data.data,
                        ...workouts.slice(index + 1)
                    ])
                }
                setWorkoutId("")
                setMovementObjects([])
                setMovementInfo([])
            })
            .catch(err => {
                setWorkoutId("")
                setMovementObjects([])
                setMovementInfo([])
            })
    }

    const colorEnum = (index) => {
        switch (index) {
            case 0:
                return "#ffea00"
            case 1:
                return "#f1f1f1"
            case 2:
                return "#90caf9"
            case 3:
                return "#ffb74d"
            case 4:
                return "#f48fb1"
            case 5:
                return "#b39ddb"
            default:
                return "#f1f1f1"
        }
    }

    return (
        <GridContainer alignItems="baseline">
            {/* <GridItem xs={12}>
                <Typography variant='h5'>Program Adı : {name} </Typography>
            </GridItem> */}
            <GridItem xs={12} md={6}>
                <Typography variant='body1'>Başlangıç : {moment(startDate).format("DD MMMM YYYY")}</Typography>
            </GridItem>
            <GridItem xs={12} md={6}>
                <DatePicker
                    label="Bitiş Zamanı"
                    minDate={moment().add(1, "month").toDate()}
                    value={endDate || moment().format('YYYY-MM-DD')}
                    onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
                    minDateMessage="Lütfen en az bir ay sonraki tarihi seçiniz."
                    format='DD MMMM YYYY'
                    okLabel='TAMAM'
                    cancelLabel='KAPAT'
                />
            </GridItem>
            <GridItem xs={12} style={{ marginBottom: 50 }}>
                <GridContainer>
                    <GridItem xs={12} md={10}>
                        <h4>Antrenmanlar</h4>

                    </GridItem>
                    {/* <GridItem xs={12} md={2}>
                        <Button color='warning' block onClick={() => {
                            // setSettingWorkout(true)
                            newWorkoutDialogToggle()
                        }} >Antrenman Ekle</Button>
                    </GridItem> */}
                    <GridItem xs={12}>
                        {workouts.map((workout, i) => {
                            return (
                                <div key={workout._id} style={{ border: "1px solid lightgray", backgroundColor: colorEnum(i), borderRadius: 20, padding: 20, marginTop: 30 }}>
                                    <GridContainer>
                                        <GridItem xs={12} md={8}>
                                            <h4> {i + 1}. Gün Antrenman Adı : {workout.name} ({workout.movements.length} hareket)</h4>
                                            <span>Dinlenme Süresi : {workoutId === workout._id ? restHours : workout.restHours} saat</span>
                                        </GridItem>
                                        <GridItem xs={12} md={4}>
                                            {workoutId === workout._id
                                                ? <>

                                                    <Button color='success' onClick={handleSaveUserWorkout} >Kaydet</Button>
                                                    <Button color='danger' onClick={() => {
                                                        setWorkoutId("")
                                                        setMovementObjects([])
                                                        setMovementInfo([])
                                                        setRestHours("")
                                                    }} >Vazgeç</Button>
                                                    {/* <Tooltip title='Antrenmanı Düzenle'>
                                                        <IconButton onClick={handleSaveUserWorkout}>
                                                            <CheckIcon color='primary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Vazgeç">
                                                        <IconButton onClick={() => {
                                                            setWorkoutId("")
                                                            setMovementObjects([])
                                                            setMovementInfo([])
                                                            setRestHours("")
                                                        }}>
                                                            <HighlightOffIcon color='secondary' />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </>
                                                : <>
                                                    <Button color='rose' onClick={() => {
                                                        setWorkoutId(workout._id)
                                                        setMovementObjects(workout.movements)
                                                        setMovementInfo(workout.movementInfo)
                                                        setRestHours(workout.restHours)
                                                        handleMovementDialogToggle()
                                                    }} >Düzenle</Button>
                                                    <Button color='danger' onClick={() => setWorkouts([
                                                        ...workouts.slice(0, i),
                                                        ...workouts.slice(i + 1)
                                                    ])} >Sil</Button>
                                                    {/* <Tooltip title='Antrenmanı Düzenle'>
                                                        <IconButton onClick={() => {
                                                            setWorkoutId(workout._id)
                                                            setMovementObjects(workout.movements)
                                                            setMovementInfo(workout.movementInfo)
                                                            setRestHours(workout.restHours)
                                                            handleMovementDialogToggle()
                                                        }}>
                                                            <EditIcon color='primary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Bu antrenmasnı sil">
                                                        <IconButton onClick={() => setWorkouts([
                                                            ...workouts.slice(0, i),
                                                            ...workouts.slice(i + 1)
                                                        ])}>
                                                            <HighlightOffIcon color='primary' />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </>
                                            }
                                        </GridItem>
                                        {workoutId === workout._id
                                            ? movementObjects.map((m, i) => {
                                                if (i < 6) {
                                                    return (
                                                        <GridItem key={m._id} xs={12} md={2} style={{ marginTop: 20 }}>
                                                            <Vimeo
                                                                video={m.videoId}
                                                                width={150}
                                                                height={150}
                                                                autopause
                                                            />
                                                            <span>A : {movementInfo[i].weight}, </span>
                                                            <span>T : {movementInfo[i].repeat}, </span>
                                                            <span>S : {movementInfo[i].sets} </span>
                                                        </GridItem>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                            : workout.movements.map((m, i) => {
                                                if (i < 6) {
                                                    return (
                                                        <GridItem key={m._id} xs={12} md={2} style={{ marginTop: 20 }}>
                                                            {m.videoUrl
                                                                ? <ReactPlayer
                                                                    url={m.videoUrl}
                                                                    width={150}
                                                                    height={150}
                                                                    controls
                                                                />
                                                                : <Vimeo
                                                                    video={m.videoId}
                                                                    width={150}
                                                                    height={150}
                                                                    autopause
                                                                />
                                                            }

                                                            <span>A : {workout.movementInfo[i].weight}, </span>
                                                            <span>T : {workout.movementInfo[i].repeat}, </span>
                                                            <span>S : {workout.movementInfo[i].sets} </span>
                                                        </GridItem>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                    </GridContainer>
                                </div>
                            )
                        })}
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12} style={{ marginBottom: 50 }}>
                <h4>Tavsiyeler</h4>
                <GridContainer alignItems='flex-end'>
                    <GridItem xs={11}>
                        <CustomInput
                            labelText='Program Tavsiyesi'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: tip,
                                onChange: (e) => setTip(e.target.value),
                                type: 'text',
                            }}
                        />
                    </GridItem>
                    <GridItem xs={1}>
                        <Tooltip title='Tavsiye Ekle'>
                            <IconButton onClick={handleAddTip}>
                                <AddCircleOutlineIcon color='primary' size={30} />
                            </IconButton>
                        </Tooltip>
                    </GridItem>
                    {tips.map((t, i) => {
                        return (
                            <Fragment key={t + i}>
                                <GridItem xs={11}>
                                    <p style={{ fontWeight: "500", fontSize: 16 }}>{t}</p>
                                </GridItem>
                                <GridItem xs={1}>
                                    <Tooltip title='Tavsiyeyi Sil'>
                                        <IconButton onClick={() => {
                                            setTips([
                                                ...tips.slice(0, i),
                                                ...tips.slice(i + 1)
                                            ])
                                        }}>
                                            <HighlightOffIcon color='primary' size={30} />
                                        </IconButton>
                                    </Tooltip>
                                </GridItem>
                                <Divider />
                            </Fragment>
                        )
                    })}
                </GridContainer>
            </GridItem>
            <GridItem xs={12}>
                <CustomInput
                    labelText='Program Açıklaması'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: description,
                        multiline: true,
                        rows: 4,
                        onChange: (e) => setDescription(e.target.value),
                        type: 'text',
                    }}
                />
            </GridItem>
            <GridItem xs={12} md={10}>

            </GridItem>
            <GridItem xs={12} md={2}>
                <Button color='success' block onClick={handleUpdate} >Workout Güncelle</Button>
            </GridItem>
            <AddMovementDialog
                open={movementDialog}
                toggle={handleMovementDialogToggle}
                movementObjects={movementObjects}
                setMovementObjects={setMovementObjects}
                movementInfo={movementInfo}
                setMovementInfo={setMovementInfo}
                restHours={restHours}
                setRestHours={setRestHours}
            />
        </GridContainer>
    )
}

export default Program