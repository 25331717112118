import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import ImageUploader from 'react-images-upload'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import LinearProgress from '@material-ui/core/LinearProgress';
import Compress from 'compress.js'
import Button from 'components/CustomButtons/Button'
import { blogPhoto } from '_services/blog'

const BLogPhoto = props => {
    const { open, toggle, id, token, setPhotoUrl } = props
    const [pictures, setPictures] = useState([])
    const [loading, setLoading] = useState(false)

    const onDrop = (file) => {
        const compress = new Compress()
        setPictures(file)
        // compress.compress(file, {
        //     size: 4, // the max size in MB, defaults to 2MB
        //     quality: .75, // the quality of the image, max is 1,
        //     maxWidth: 600, // the max width of the output image, defaults to 1920px
        //     maxHeight: 600, // the max height of the output image, defaults to 1920px
        //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
        // }).then((data) => {
        //     if(Array.isArray(data)){
        //         let newPictures = []
        //         for(let i = 0; i< data.length; i++){
        //             const base64str = data[i].data
        //             const imgExt = "image/png"
        //             const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
        //             newPictures = [ ...newPictures, resizedFile ]
        //         }
        //         setPictures(newPictures)
        //     }
        // })
    }

    const submitOperation = fd => {
        setLoading(true)
        blogPhoto(token, fd)
            .then(res => {
                toggle()
                setLoading(false)
                console.log(res.data)
                if (res.data && res.data.code === 200) {
                    setPhotoUrl(res.data.data.photoUrl)
                    setPictures([])
                    return Swal.fire({
                        icon: 'success',
                        title: 'İşlem Başarılı!',
                        timer: 2000,
                        showConfirmButton: false
                    })
                } else {
                    return Swal.fire({
                        icon: 'error',
                        title: 'Bir hata oluştu.'
                    })
                }
            })
            .catch(error => {
                toggle()
                setLoading(false)
                return Swal.fire({
                    icon: 'error',
                    title: 'Bir hata oluştu : ' + error
                })
            })
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (pictures.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Lütfen dosya seçiniz.'
            })
        } else {
            const fd = new FormData();
            fd.append('id', id)
            fd.append(('photo'), pictures[0], pictures[0].name)
            submitOperation(fd)
        }
    }

    return (
        <Dialog open={open} onClose={toggle} fullWidth maxWidth="sm">
            <DialogTitle>Fotoğraf Yükle</DialogTitle>
            <DialogContent>
                <ImageUploader
                    withIcon={true}
                    buttonText='Fotoğraf Seçiniz'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.jpeg', '.png']}
                    maxFileSize={5242880}
                    fileTypeError='Desteklenmeyen dosya formatı!!'
                    fileSizeError='Dosya çok büyük!'
                    label='Maks 5mb, jpeg veya png.'
                    withPreview={true}
                    singleImage
                />
            </DialogContent>
            <DialogActions>
                {loading
                    ? <LinearProgress color="secondary" />
                    : <Button color='success' block onClick={handleSubmit}> Yükle</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default BLogPhoto