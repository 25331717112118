import axios from 'axios';
import { domain } from './domain';

export const createBlog = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/blog', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const blogPhoto = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/blogphoto', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getBlog = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getblog', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getBlogs = (data) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/blog', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};