import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput';

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { MessageList, Input } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css';
import { getConservation } from '_services/questions';
import { updateQuestion } from '_services/questions';
import moment from 'moment';

import { io } from "socket.io-client";

const socket = io("https://www.mpfit.com.tr", { path : "/socket", transports : ['websocket'] });

const useStyles = makeStyles((theme) => ({
    ...styles,
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionDialog = props => {
    const { open, toggle, id, token } = props
    const [conversation, setConversation] = useState([])
    const [messages, setMessages] = useState([])
    const [text, setText] = useState("")
    const [questionEnum, setQuestionEnum ] = useState(0)
    const [ userId, setUserId ] = useState("")

    useEffect(() => {
        const data = { id }
        getConservation(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    console.log(res.data)
                    setConversation(res.data.data.conversation)
                    setQuestionEnum(res.data.data.questionEnum || 0)
                    setUserId(res.data.data.user)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [id])

    useEffect(() => {
        let messages = conversation.map(c => ({ type: 'text', text: c.text, date: moment(c.created).toDate(), position: c.isUser ? "left" : "right" }))
        messages = messages.sort((a, b) => a.date < b.date ? -1 : 1)
        setMessages(messages)
    }, [conversation])

    const handleClose = () => {
        toggle()
    }

    const classes = useStyles()

    const enums = index => {
        switch (index) {
            case 0:
                return "Genel Admin"
            case 1:
                return "Bilgehan Koç"
            case 2:
                return "Mpfit Uzmanını"
            case 3:
                return "Spor Beslenme Uzmanını"
            case 4:
                return "Anabolik Uzmanını"
            case 5:
                return "Doktor"
            case 6:
                return "Spor Psikoloğu"
            default:
                return
        }
    }

    const handleSendMessage = () => {
        if (text === "") {
            return
        }
        let created = Date.now()
        let isUser = false
        let isAnswered = true
        let isSeenByUser = false
        const newConversation = [
            ...conversation,
            { text, created, isUser, isAnswered, isSeenByUser }
        ]
        const data = { id, conversation: newConversation, text : text, title : enums(questionEnum), alert : true  }
        updateQuestion(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setConversation(res.data.data.conversation)
                    setText("")
                    socket.emit("bildirim", { id : userId })
                }
            })
            .catch(err => {
                console.log("err")
            })
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" >
            <AppBar className={classes.appBar} color='primary'>
                <Toolbar color='primary'>
                    <Typography variant="h6" className={classes.title}>
                        Mesajlasma
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={messages} />
            </DialogContent>
            <DialogActions>

                <GridContainer>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText='Mesajınız...'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: text,
                                onChange: (e) => setText(e.target.value),
                                onKeyPress : e => {
                                    const { key } = e
                                    if(key === "Enter"){
                                        handleSendMessage()
                                    }
                                },
                                endAdornment: (
                                    <Button onClick={handleSendMessage} color='rose'>Yolla</Button>
                                ),
                            }}
                        />
                    </GridItem>
                    {/* <GridItem xs={2}>
                        <Button onClick={handleSendMessage} color='rose'>Yolla</Button>
                    </GridItem> */}
                </GridContainer>
            </DialogActions>
        </Dialog>
    )
}

export default QuestionDialog