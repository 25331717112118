import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import DataGrid from 'components/DataGrid/SimpleGrid'
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import { getMovements } from "_services/workout"
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Vimeo from '@u-wave/react-vimeo';
import { getMuscleGroups } from "_services/workout"
import { useAtom } from "jotai"
import { movementsAtom } from "_atoms/movements"
import { movementsFilter } from "_atoms/movements"
import { movementPage } from "_atoms/movements"
import ReactPlayer from "react-player"

const styles = {

}

const useStyles = makeStyles(styles)

const Movements = props => {
    const [muscleGroups, setMuscleGroups] = useState([])
    const [muscleGroup, setMuscleGroup] = useAtom(movementsFilter)
    const [movements, setMovements] = useAtom(movementsAtom)
    const [ page, setPage] = useAtom(movementPage)
    const classes = useStyles()

    const columns = [
        { name: 'video', title: "Video" },
        { name: 'name', title: "Hareket Adı" },
        { name: 'muscleGroups', title: "Kas Grupları" },
        { name: 'description', title: "Açıklama" },
        { name: 'action', title: "İşlem" }
    ];

    const muscleResolver = groups => {
        let text = ''
        for (let i = 0; i < groups.length; i++) {
            if ((i + 1) === groups.length) {
                text = text + ' ' + groups[i].name
            } else {
                text = text + groups[i].name + ', '
            }
        }
        return text
    }

    const rows = () => {
        return movements.map((p) => ({
            name: p.name || '',
            video: (p.videoId && p.videoId !== '')
                ? <Vimeo
                    video={p.videoId}
                    width={150}
                    height={150}
                    autopause
                    onError={err => console.log(err)}
                />
                : <ReactPlayer url={p.videoUrl} height={150} width={150} controls />,
            description: p.description || '',
            muscleGroups: muscleResolver(p.muscleGroups || []),
            action: <Link to={{ pathname: "/admin/hareket-duzenle", movement: p }}>
                <Tooltip title='Düzenle'>
                    <IconButton color='primary' >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Link>

        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 350 },
        { columnName: 'description', width: 400 },
        { columnName: 'muscleGroups', width: 200 },
        { columnName: 'video', width: 200 },
        { columnName: 'action', width: 150 }
    ];

    useEffect(() => {
        const data = { isActive: true }
        getMuscleGroups(data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setMuscleGroups(res.data.data)
                }
                else {
                    console.log(res.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if ( muscleGroup === "" && muscleGroups.length > 0) {
            setMuscleGroup(muscleGroups[0]._id)
        }
    }, [muscleGroups])

    useEffect(() => {
        if (muscleGroup !== "") {
            const data = { isActive: true, muscleGroups: muscleGroup }
            getMovements(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setMovements(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [muscleGroup])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={10}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}> Hareketler </h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={2}>
                                <Link to={{ pathname: "/admin/hareket-duzenle" }}>
                                    <Button color='success' block>Ekle</Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            <GridItem xs={12}>
                {muscleGroups.map(m => {
                    return (
                        <Button color={muscleGroup === m._id ? 'success' : "info"} key={m} onClick={() => setMuscleGroup(m._id)} style={{ marginRight: 10, marginBottom: 5 }} >{m.name}</Button>
                    )
                })}
            </GridItem>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                            page = {page}
                            setPage = {setPage}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer >
    )
}

export default Movements