import React, { useEffect, useState } from 'react'
import { getBlogs } from '_services/blog'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';
import { getAgreements } from '_services/agreement';

const Agreements = props => {

    const [ agreements, setAgreements ] = useState([])

    useEffect(() => {
        getAgreements()
        .then( res => {
            console.log(res.data)
            if(res.data && res.data.code === 200){
                setAgreements(res.data.data)
            }
        })
        .catch( err => {
            console.log(err)
        })
    },[])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Sözleşmeler</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/sozlesme-detay'>
                                    <Button color='success' style={{ float: "right" }}> Sözleşme Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            { agreements.map((b,i) => {
                return (
                    <GridItem xs={12} md={4} key={b._id}>
                        <Card>
                            <CardBody>
                                <h4 style={{fontWeight : "bold"}}>{b.title}</h4>
                            </CardBody>
                            <CardFooter style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Link to={{ pathname: '/admin/sozlesme-detay', agreements, agreementIndex : i }}>
                                    <Button color='rose' style={{ float: "right" }}> Düzenle </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}
        </GridContainer>
    )
}

export default Agreements