import React, { useEffect, useState } from 'react'
import { getBlogs } from '_services/blog'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';

const Blogs = props => {

    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        getBlogs()
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setBlogs(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Blog Yazıları</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/blog-detay'>
                                    <Button color='success' style={{ float: "right" }}> Blog Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            { blogs.map(b => {
                return (
                    <GridItem xs={12} md={4} key={b._id}>
                        <Card>
                            <div style={{ height: 250 }}>
                                <img src={b.photoUrl} alt={b.title} style={{ height: "100%", objectFit: "cover", width: "100%" }} />
                            </div>
                            <CardBody>
                                <h4>{b.title}</h4>
                                <p>{b.meta}</p>
                            </CardBody>
                            <CardFooter style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Link to={{ pathname: '/admin/blog-detay', blog: b }}>
                                    <Button color='info' style={{ float: "right" }}> Düzenle </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}
        </GridContainer>
    )
}

export default Blogs