import axios from 'axios';
import { domain } from './domain';

export const getCustomers = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getusers', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateCustomer = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/adminuser', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomersWithPackages = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getuserswithpackage', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomerById = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getuserdetails', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomerMeasures = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getusermeasures', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const individualNotification = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/individualnotification', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const bulkNotification = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/bulknotification', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};