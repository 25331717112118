import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardFooter from 'components/Card/CardFooter'
import CardHeader from 'components/Card/CardHeader'
import React, { useEffect, useState } from 'react'
import { getUserPrograms, createUserWorkout } from '_services/workout'
import Button from 'components/CustomButtons/Button'
import Swal from 'sweetalert2'
import CustomInput from 'components/CustomInput/CustomInput'
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Divider, Tooltip, IconButton, Typography } from "@material-ui/core"
import { Fragment } from "react"
import NewWorkoutDialog from './NewWorkoutDialog'
import AddMovementDialog from 'views/Workouts/AddMovementDialog'
import { createUserProgram } from '_services/workout'
import Program from './Program'
import Vimeo from '@u-wave/react-vimeo';
import { updateCustomer } from '_services/user'
import DataGrid from 'components/DataGrid/SimpleGrid'
import ReactPlayer from 'react-player'

const Workouts = props => {
    const [programs, setPrograms] = useState([])
    const [currentProgram, setCurrentProgram] = useState({})
    const [showNewWorkoutDialog, setShowNewWorkoutDialog] = useState(false)
    const [movementDialog, setMovementDialog] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const { id, customer, token, setCustomer } = props
    const [newUserWorkout, setNewUserWorkout] = useState({})
    const [movementObjects, setMovementObjects] = useState([])
    const [movementInfo, setMovementInfo] = useState([])
    const [settingWorkout, setSettingWorkout] = useState(false)
    const [workouts, setWorkouts] = useState([])
    const [editMode, setEditMode] = useState(false)

    const [name, setName] = useState("")
    const [endDate, setEndDate] = useState(moment().add(1, "month").toDate())
    const [tips, setTips] = useState([])
    const [description, setDescription] = useState("")

    const [tip, setTip] = useState("")

    useEffect(() => {
        setMovementInfo(newUserWorkout.movementInfo || [])
        setMovementObjects(newUserWorkout.movements || [])
    }, [newUserWorkout])

    const newWorkoutDialogToggle = (cancel) => {
        if (cancel) {
            setSettingWorkout(false)
        }
        setShowNewWorkoutDialog(!showNewWorkoutDialog)
    }

    const handleMovementDialogToggle = () => {
        setMovementDialog(!movementDialog)
    }

    const handleCurrent = id => {
        const data = { id: customer._id, currentProgram: id }
        updateCustomer(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setCustomer(res.data.data)
                    Swal.fire({
                        icon: "success",
                    })
                } else {
                    Swal.fire({
                        icon: "error"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error"
                })
            })
    }

    useEffect(() => {
        if (customer && customer._id) {
            const data = { user: customer }
            getUserPrograms(data)
                .then(res => {
                    if (res.data && res.data.code === 200 && res.data.data.length > 0) {
                        setPrograms(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [customer])

    // useEffect(() => {
    //     console.log(currentProgram)
    // },[currentProgram])

    const handleAddNewProgram = () => {
        setAddMode(true)
        // const data = { user: customer._id, endDate: moment().add(1,"M").toDate(), startDate: moment().toDate() }
        // createUserProgram(token,data)
        // .then( res => {
        //     console.log(res.data)
        //     if(res.data && res.data.code === 200){
        //         setCurrentProgram(res.data.data)
        //     }
        // })
        // .catch( err => {
        //     console.log(err)
        // })
        // if (currentProgram._id) {
        //     Swal.fire({
        //         icon: "warning",
        //         title: 'Mevcut Programı Değiştirecek misiniz?',
        //         showDenyButton: true,
        //         confirmButtonText: `Evet, Yeni Program`,
        //         denyButtonText: `Vazgeç`,
        //     })
        //         .then((result) => {
        //             /* Read more about isConfirmed, isDenied below */
        //             if (result.isConfirmed) {
        //                 setName("")
        //                 setWorkouts([])
        //                 setTips([])
        //                 setDescription('')
        //                 setEndDate('')
        //                 setAddMode(true)
        //             } else if (result.isDenied) {
        //                 setShowNewWorkoutDialog(true)
        //             }
        //         })
        // } else {
        //     setAddMode(true)
        // }
    }

    const handleGiveUp = () => {
        setAddMode(false)
        setName("")
        setEndDate("")
        setTips([])
        setDescription("")
        setTip("")
    }

    const handleAddTip = () => {
        if (tip.length > 5) {
            setTips([
                ...tips,
                tip
            ])
            setTip("")
        } else {
            Swal.fire({
                icon: "warning",
                title: "Tavsiye çok kısa!"
            })
        }
    }

    const handleCreateUserWorkout = () => {
        const data = { user: id, name: newUserWorkout.name, description: newUserWorkout.description, tips: newUserWorkout.tips, restHours: newUserWorkout.restHours, movements: movementObjects.map(m => m._id), movementInfo }
        createUserWorkout(token, data)
            .then(res => {
                console.log(res.data.data)
                if (res.data && res.data.code === 200) {
                    setWorkouts([
                        ...workouts,
                        res.data.data
                    ])
                    setSettingWorkout(false)
                    setNewUserWorkout({})
                    setMovementObjects([])
                    setMovementInfo([])
                    Swal.fire({
                        icon: "success",
                        title: "Antrenman bu kullanıcıya atandı!"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Bir hata oluştu"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
                console.log(err)
            })
    }

    const handleCreateUserProgram = () => {
        const data = { user: id, workouts: workouts.map(w => w._id), endDate: moment(endDate).toDate(), startDate: moment().toDate(), name, tips, description }
        createUserProgram(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setPrograms([
                        ...programs,
                        res.data.data
                    ])
                    setCurrentProgram({})
                    setAddMode(false)
                    Swal.fire({
                        icon: "success",
                        title: "Program Oluşturuldu."
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Bir hata oluştu"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir hata oluştu!"
                })
            })
    }

    if (settingWorkout) {
        return (
            <div style={{ width: "100%", overflow: "hidden" }}>
                <GridContainer alignItems='center' >
                    <GridItem xs={11}>
                        {newUserWorkout._id &&
                            <GridContainer justify='center'>
                                <GridItem xs={12}>
                                    <div key={newUserWorkout._id} style={{ border: "1px solid lightgray", borderRadius: 20, padding: 20, marginTop: 30 }}>
                                        <GridContainer>
                                            <GridItem xs={12} md={8}>
                                                <h4>Antrenman Adı : {newUserWorkout.name} ({movementObjects.length} hareket)
                                                    {/* <Tooltip title="Hareketleri Gör / Düzenle" >
                                                        <IconButton onClick={handleMovementDialogToggle}>
                                                            <EditIcon color='primary' size={30} />
                                                        </IconButton>
                                                    </Tooltip> */}
                                                </h4>
                                                <span>Dinlenme Süresi : {newUserWorkout.restHours} saat</span>
                                            </GridItem>
                                            <GridItem xs={12} md={2}>
                                                <Button color='rose' onClick={handleMovementDialogToggle}>Düzenle</Button>
                                            </GridItem>
                                            <GridItem xs={12} md={2}>
                                                <Button color='danger' onClick={() => {
                                                    setSettingWorkout(false)
                                                    setNewUserWorkout({})
                                                    setMovementObjects([])
                                                    setMovementInfo([])
                                                }}
                                                >Vazgeç</Button>
                                                {/* <Tooltip title="Antrenman Eklemekten Vazgeç">
                                                    <IconButton onClick={() => {
                                                        setSettingWorkout(false)
                                                        setNewUserWorkout({})
                                                        setMovementObjects([])
                                                        setMovementInfo([])
                                                    }}>
                                                        <HighlightOffIcon color='primary' size={30} />
                                                    </IconButton>
                                                </Tooltip> */}
                                            </GridItem>
                                            {movementObjects.map((m, i) => {
                                                if (i < 6) {
                                                    return (
                                                        <GridItem key={m._id} style={{ marginTop: 20 }}>
                                                            {m.videoUrl
                                                                ? <ReactPlayer
                                                                    video={m.videoUrl}
                                                                    width={150}
                                                                    height={150}
                                                                    controls
                                                                />
                                                                : <Vimeo
                                                                    video={m.videoId}
                                                                    width={150}
                                                                    height={150}
                                                                    autopause
                                                                />
                                                            }

                                                            <span>A : {movementInfo[i].weight}, </span>
                                                            <span>T : {movementInfo[i].repeat}, </span>
                                                            <span>S : {movementInfo[i].sets} </span>
                                                        </GridItem>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                        </GridContainer>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={10}>
                                </GridItem>
                                <GridItem xs={12} md={2}>
                                    <Button color='success' block onClick={handleCreateUserWorkout}>Antrenmanı Ekle</Button>
                                </GridItem>
                            </GridContainer>
                        }
                    </GridItem>
                </GridContainer>
                <NewWorkoutDialog
                    setSettingWorkout={setSettingWorkout}
                    open={showNewWorkoutDialog}
                    toggle={newWorkoutDialogToggle}
                    setNewUserWorkout={setNewUserWorkout}
                    setName={setName}
                />
                {newUserWorkout._id && movementObjects.length > 0 && movementInfo.length === movementObjects.length &&
                    <AddMovementDialog
                        open={movementDialog}
                        toggle={handleMovementDialogToggle}
                        movementObjects={movementObjects}
                        setMovementObjects={setMovementObjects}
                        movementInfo={movementInfo}
                        setMovementInfo={setMovementInfo}
                    />
                }
            </div>
        )
    }

    const columns = [
        { name: 'name', title: "Adı" },
        { name: 'isActive', title: "Aktif/Pasif" },
        { name: 'starts', title: "Başlangıç" },
        { name: 'ends', title: "Bitiş" },
        { name: 'movements', title: "Antrenman Sayısı" },
        { name: 'action', title: "İşlem" }
    ];

    //     programs.map(p => {
    //         return (
    // <GridItem xs={12} md={4} key={p._id}>
    //     <Card style={{ border: "1px solid #f1f1f1", }}>
    //         <CardHeader>
    //             <GridContainer>
    //                 <GridItem xs={9}>
    //                     <h4>Program Adı : {p.name}</h4>
    //                 </GridItem>
    //                 <GridItem xs={3}>
    //                     {customer.currentProgram && customer.currentProgram._id === p._id && <h4>Aktif</h4>}
    //                 </GridItem>
    //             </GridContainer>
    //             <h5>Başlangıç : {moment(p.startDate).format("DD MMMM YY")}</h5>
    //             <h5>Bitiş : {moment(p.endDate).format("DD MMMM YY")}</h5>
    //             <h5>Antrenman Sayısı : {p.workouts.length}</h5>
    //         </CardHeader>
    //         <CardFooter>
    //             <Button color='rose' onClick={() => {
    //                 setEditMode(true)
    //                 setCurrentProgram(p)
    //             }}>Düzenle</Button>
    //             <Button color='success' onClick={() => handleCurrent(p._id)} >Seç</Button>
    //         </CardFooter>
    //     </Card>
    // </GridItem>
    // )

    const rows = () => {
        return programs.map((p) => ({
            name: p.name || '',
            isActive: (customer.currentProgram && customer.currentProgram._id === p._id) ? <h4>Aktif</h4> : <h4>Pasif</h4>,
            starts: moment(p.startDate).format("DD MMMM YY"),
            ends: moment(p.endDate).format("DD MMMM YY"),
            movements: p.workouts.length,
            action: <>
                <Button color='rose' onClick={() => {
                    setEditMode(true)
                    setCurrentProgram(p)
                }}>Düzenle</Button>
                <Button color='success' onClick={() => handleCurrent(p._id)} >Seç</Button>
            </>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 200 },
        { columnName: 'isActive', width: 200 },
        { columnName: 'starts', width: 200 },
        { columnName: 'ends', width: 200 },
        { columnName: 'movements', width: 200 },
        { columnName: 'action', width: 250 }
    ];

    const colorEnum = (index) => {
        switch (index) {
            case 0:
                return "#ffea00"
            case 1:
                return "#f1f1f1"
            case 2:
                return "#90caf9"
            case 3:
                return "#ffb74d"
            case 4:
                return "#f48fb1"
            case 5:
                return "#b39ddb"
            default:
                return "#f1f1f1"
        }
    }

    if (addMode) {
        return (
            <div style={{ width: "100%", overflow: "hidden" }}>
                <GridContainer alignItems='center' >
                    <GridItem xs={12} md={10}>

                    </GridItem>
                    <GridItem xs={12} md={2}>
                        <Button color='rose' block onClick={handleGiveUp}>Vazgeç</Button>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <CustomInput
                            labelText='Program Adı'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            style={{ marginTop: 17 }}
                            inputProps={{
                                value: name,
                                onChange: (e) => setName(e.target.value),
                                type: 'text',
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <DatePicker
                            label="Bitiş Zamanı"
                            minDate={moment().add(1, "month").toDate()}
                            value={endDate || moment().format('YYYY-MM-DD')}
                            onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
                            format='DD MMMM YYYY'
                            okLabel='TAMAM'
                            cancelLabel='KAPAT'
                        />
                    </GridItem>
                    <GridItem xs={12} md={4}>

                    </GridItem>
                    <GridItem xs={12} style={{ marginBottom: 50 }}>
                        <GridContainer>
                            <GridItem xs={12} md={10}>
                                <h4>Antrenmanlar</h4>
                            </GridItem>
                            <GridItem xs={12} md={2}>
                                <Button color='warning' block onClick={() => {
                                    setSettingWorkout(true)
                                    newWorkoutDialogToggle()
                                }} >Antrenman Ekle</Button>
                            </GridItem>
                            <GridItem xs={12}>
                                <GridContainer>
                                    {workouts.map((w, i) => {
                                        return (
                                            <Fragment key={w._id}>
                                                <GridItem xs={12} md={6}>
                                                    <div style={{ border: "1px solid lightgray", backgroundColor: colorEnum(i), borderRadius: 20, padding: 20, marginTop: 30 }}>
                                                        <Typography variant='h6'>
                                                            {i + 1}. Gün {w.name} {w.movements.length} hareket
                                                            <Tooltip title='Antrenmanı Çıkar'>
                                                                <IconButton onClick={() => {
                                                                    setWorkouts([
                                                                        ...workouts.slice(0, i),
                                                                        ...workouts.slice(i + 1)
                                                                    ])
                                                                }}>
                                                                    <HighlightOffIcon color='primary' size={30} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                        <Typography variant="body1">Dinlenme Süresi : {w.restHours} saat</Typography>
                                                    </div>
                                                </GridItem>
                                                <GridItem xs={12} md={6}>
                                                </GridItem>
                                            </Fragment>
                                        )
                                    })}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} style={{ marginBottom: 50 }}>
                        <h4>Tavsiyeler</h4>
                        <GridContainer alignItems='flex-end'>
                            <GridItem xs={11}>
                                <CustomInput
                                    labelText='Program Tavsiyesi'
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: tip,
                                        onChange: (e) => setTip(e.target.value),
                                        type: 'text',
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={1}>
                                <Tooltip title='Tavsiye Ekle'>
                                    <IconButton onClick={handleAddTip}>
                                        <AddCircleOutlineIcon color='primary' size={30} />
                                    </IconButton>
                                </Tooltip>
                            </GridItem>
                            {tips.map((t, i) => {
                                return (
                                    <Fragment key={t + i}>
                                        <GridItem xs={11}>
                                            <p style={{ fontWeight: "500", fontSize: 16 }}>{t}</p>
                                        </GridItem>
                                        <GridItem xs={1}>
                                            <Tooltip title='Tavsiyeyi Sil'>
                                                <IconButton onClick={() => {
                                                    setTips([
                                                        ...tips.slice(0, i),
                                                        ...tips.slice(i + 1)
                                                    ])
                                                }}>
                                                    <HighlightOffIcon color='primary' size={30} />
                                                </IconButton>
                                            </Tooltip>
                                        </GridItem>
                                        <Divider />
                                    </Fragment>
                                )
                            })}
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                        <CustomInput
                            labelText='Program Açıklaması'
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: description,
                                multiline: true,
                                rows: 4,
                                onChange: (e) => setDescription(e.target.value),
                                type: 'text',
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} md={10}>

                    </GridItem>
                    <GridItem xs={12} md={2}>
                        <Button color='success' block onClick={handleCreateUserProgram}>Workout Oluştur</Button>
                    </GridItem>
                </GridContainer>
                <NewWorkoutDialog
                    setSettingWorkout={setSettingWorkout}
                    open={showNewWorkoutDialog}
                    toggle={newWorkoutDialogToggle}
                    setNewUserWorkout={setNewUserWorkout}
                    setName={setName}
                />
            </div>
        )
    }

    return (
        <div style={{ width: "100%", overflow: "hidden" }}>
            <GridContainer>
                <GridItem xs={12} md={10}>

                </GridItem>
                <GridItem xs={12} md={2}>
                    {currentProgram._id && !editMode
                        ? null
                        : <Button color='success' block onClick={handleAddNewProgram}>Workout Ekle</Button>
                    }
                </GridItem>
                {!currentProgram._id
                    ? <GridItem xs={12}>
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                        />
                    </GridItem>

                    //     programs.map(p => {
                    //         return (
                    // <GridItem xs={12} md={4} key={p._id}>
                    //     <Card style={{ border: "1px solid #f1f1f1", }}>
                    //         <CardHeader>
                    //             <GridContainer>
                    //                 <GridItem xs={9}>
                    //                     <h4>Program Adı : {p.name}</h4>
                    //                 </GridItem>
                    //                 <GridItem xs={3}>
                    //                     {customer.currentProgram && customer.currentProgram._id === p._id && <h4>Aktif</h4>}
                    //                 </GridItem>
                    //             </GridContainer>
                    //             <h5>Başlangıç : {moment(p.startDate).format("DD MMMM YY")}</h5>
                    //             <h5>Bitiş : {moment(p.endDate).format("DD MMMM YY")}</h5>
                    //             <h5>Antrenman Sayısı : {p.workouts.length}</h5>
                    //         </CardHeader>
                    //         <CardFooter>
                    //             <Button color='rose' onClick={() => {
                    //                 setEditMode(true)
                    //                 setCurrentProgram(p)
                    //             }}>Düzenle</Button>
                    //             <Button color='success' onClick={() => handleCurrent(p._id)} >Seç</Button>
                    //         </CardFooter>
                    //     </Card>
                    // </GridItem>
                    // )
                    //     })
                    : <>
                        <GridItem xs={12} md={10}>

                        </GridItem>
                        <GridItem xs={12} md={2}>
                            <Button color='rose' block onClick={() => setCurrentProgram({})}>Programlar</Button>
                        </GridItem>
                    </>
                }
                <GridItem xs={12}>
                    {currentProgram._id
                        ? <Program program={currentProgram} setProgram={setCurrentProgram} token={token} setSettingWorkout={setSettingWorkout} newWorkoutDialogToggle={newWorkoutDialogToggle} />
                        : <span></span>
                    }
                </GridItem>
            </GridContainer>
        </div>

    )
}

export default Workouts