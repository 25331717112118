import React, { useEffect, useState } from 'react'
import { getBlogs } from '_services/blog'
import { makeStyles } from '@material-ui/core/styles'
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';
import { getCoaches } from '_services/coach';
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';

const Coaches = props => {
    const [ user ] = useAtom(userAtom)
    const [ coaches, setCoaches ] = useState([])

    const { token } = user

    useEffect(() => {
        getCoaches(token)
        .then( res => {
            console.log(res.data)
            if(res.data && res.data.code === 200){
                setCoaches(res.data.data)
            }
        })
        .catch( err => {
            console.log(err)
        })
    },[])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={8}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}>Koçlar</h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={4}>
                                <Link to='/admin/koc-detay'>
                                    <Button color='success' style={{ float: "right" }}> Koç Ekle </Button>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                </Card>
            </GridItem>
            { coaches.map((b,i) => {
                return (
                    <GridItem xs={12} md={4} key={b._id}>
                        <Card>
                            <CardBody>
                                <h4 style={{fontWeight : "bold"}}>{b.name + " " + b.surname}</h4>
                                <p>{b.username}</p>
                            </CardBody>
                            <CardFooter style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Link to={{ pathname: '/admin/koc-detay', coaches, coachIndex : i }}>
                                    <Button color='rose' style={{ float: "right" }}> Düzenle </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}
        </GridContainer>
    )
}

export default Coaches